import { SET_TIMEZONE } from "./types";
import { getUserData } from "../utils/userData";

export const setTimezone = (tzOption = "0") => async dispatch => {
  let timeZone = "";

  switch(tzOption.toString()) {
    case "1":
        const user = await getUserData();
        timeZone = user.Timezone;
        
        if(!timeZone) {
          timeZone = "";
        }
        break;
    default:
        timeZone = "";
        break;
}
  dispatch({
    type: SET_TIMEZONE,
    payload: { tzOption: timeZone }
  });
};
