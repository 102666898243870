import React, { useState } from "react";

const SearchField = props => {
  const [formData, setFormData] = useState({
    queryParam: "title",
    query: "",
    filter: "All"
  });

  const onChange = e => {
    const data = { ...formData, [e.target.name]: e.target.value };
    setFormData(data);
    props.handleSearch(data.queryParam, data.query, data.filter);
  };

  return (
    <form className="sub-nav-item">
      <div className="row">
        <div className="col- pr-1">
          <input
            type={formData.queryParam === "createdAt" ? "date" : "text"}
            name="query"
            placeholder={formData.queryParam === "createdAt" ? "YYYY-MM-DD" : "Search for..."}
            value={formData.query}
            onChange={e => onChange(e)}
          />
        </div>
      </div>
      <div className="dropdown">
        <button
          className="dropdown-link dropdown-toggle"
          id="searchParam"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Search by:{" "}
          {formData.queryParam === "createdAt"
            ? "Date Created"
            : formData.queryParam === "createdBy"
            ? " Created By"
            : "Title"}
        </button>
        <div className="dropdown-menu" aria-labelledby="searchParam">
          <label className="dropdown-item">
            <input
              type="radio"
              name="queryParam"
              onChange={e => onChange(e)}
              className="hidden"
              value="title"
              checked={formData.queryParam === "title"}
            />
            Title
          </label>
          <label className="dropdown-item">
            <input
              type="radio"
              name="queryParam"
              onChange={e => onChange(e)}
              className="hidden"
              value="createdBy"
              checked={formData.queryParam === "createdBy"}
            />
            Created By
          </label>
          <label className="dropdown-item">
            <input
              type="radio"
              name="queryParam"
              onChange={e => onChange(e)}
              className="hidden"
              value="createdAt"
              checked={formData.queryParam === "createdAt"}
            />
            Date Created
          </label>
        </div>
      </div>
      {props.useFilter && (
        <div className="dropdown">
          <button
            className="dropdown-link dropdown-toggle"
            id="searchFilter"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Filter by: {formData.filter}
          </button>
          <div className="dropdown-menu" aria-labelledby="searchFilter">
            <label className="dropdown-item">
              <input
                type="radio"
                name="filter"
                onChange={e => onChange(e)}
                className="hidden"
                value="All"
                checked={formData.filter === "All"}
              />
              All
            </label>
            <label className="dropdown-item">
              <input
                type="radio"
                name="filter"
                onChange={e => onChange(e)}
                className="hidden"
                value="Not published"
                checked={formData.filter === "Not published"}
              />
              Not published
            </label>
            <label className="dropdown-item">
              <input
                type="radio"
                name="filter"
                onChange={e => onChange(e)}
                className="hidden"
                value="Published"
                checked={formData.filter === "Published"}
              />
              Published
            </label>
            <label className="dropdown-item">
              <input
                type="radio"
                name="filter"
                onChange={e => onChange(e)}
                className="hidden"
                value="Not reviewed"
                checked={formData.filter === "Not reviewed"}
              />
              Not reviewed
            </label>
            <label className="dropdown-item">
              <input
                type="radio"
                name="filter"
                onChange={e => onChange(e)}
                className="hidden"
                value="Reviewed"
                checked={formData.filter === "Reviewed"}
              />
              Reviewed
            </label>
          </div>
        </div>
      )}
    </form>
  );
};

export default SearchField;
