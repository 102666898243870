import React from "react";

const ClickableCard = ({ body, onClick }) => {
    return (
        <div className="card" onClick={(e) => onClick(e)}>
            <div className="card-body clickable-card">
                {body}
            </div>
        </div>
    )
}

export default ClickableCard;