import React, { Fragment } from "react";
import NotAuthorizedImg from "../../img/lablynx/401.png";
import logger from "../../utils/logger";
import { connect } from "react-redux"



const NotAuthorized = ({user}) => {

  logger('error',{type: '401', href: window.location.href, user: user} )
  return (
    <Fragment>
      <img src={NotAuthorizedImg} alt="Not Authorized" width="100%" height="600" />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(NotAuthorized);
