import { LOCATION_LOADED, LOCATION_ERROR, LOCATION_CREATED, LOCATION_UPDATED, LOCATION_SET, LOCATION_SET_CURRENT } from "./types";
import { createLocation, updateLocationById, getAllUserLocations, setCurrentUserLocation, getCurrentUserLocation, setUserLocations } from "../utils/apiCall";

export const loadUserLocations = () => async (dispatch) => {
	try {
		const locations = await getAllUserLocations();
		const currentLocation = await getCurrentUserLocation();

		dispatch({
			type: LOCATION_LOADED,
			payload: {
				locations: locations,
				currentLocation: currentLocation,
			},
		});
	} catch (err) {
		dispatch({
			type: LOCATION_ERROR,
		});
	}
};

export const createLocationAction = (name) => async (dispatch) => {
	try {
		const location = await createLocation(name);

		dispatch({
			type: LOCATION_CREATED,
			payload: location,
		});

		dispatch(loadUserLocations());
	} catch (err) {
		dispatch({
			type: LOCATION_ERROR,
		});
	}
};

export const updateLocation = (locationId, name) => async (dispatch) => {
	try {
		const location = await updateLocationById(locationId, name);

		dispatch({
			type: LOCATION_UPDATED,
			payload: location,
		});

		dispatch(loadUserLocations());
	} catch (err) {
		dispatch({
			type: LOCATION_ERROR,
		});
	}
};

export const setCurrentUserLocationAction = (locationId) => async (dispatch) => {
	try {
		const location = await setCurrentUserLocation(locationId);

		dispatch({
			type: LOCATION_SET_CURRENT,
			payload: location,
		});
	} catch (err) {
		dispatch({
			type: LOCATION_ERROR,
		});
	}
};

export const setUserLocationsAction = (userId, locationIdArr) => async (dispatch) => {
	try {
		const locations = await setUserLocations(userId, locationIdArr);

		dispatch({
			type: LOCATION_SET,
			payload: locations,
		});
	} catch (err) {
		dispatch({
			type: LOCATION_ERROR,
		});
	}
};
