import { PROJECT_TYPES_ERROR, PROJECT_TYPE_CREATED, PROJECT_TYPES_LOADED, PROJECT_TYPE_UPDATED } from "../actions/types";

const initialState = {
  loading: true,
  projectTypes: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROJECT_TYPES_LOADED:
        return {
            ...state,
            loading: false,
            projectTypes: payload
          };
    case PROJECT_TYPE_UPDATED:
    case PROJECT_TYPE_CREATED:
      return {
        ...state,
        loading: true,
      };
    case PROJECT_TYPES_ERROR:
        return {
            ...state
        }
    default:
      return state;
  }
}
