import React, { useState, useEffect } from "react";
import SideBarHeader from "./SideBarHeader";

const SideBar = ({ projectList }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(projectList) {
      const projectsRes = projectList.filter(project => project.active);
      let projectTypes = [];
      let projectsArr = [];

      for(let i = 0; i < projectsRes.length; i++) {
        const project = projectsRes[i];
        const projectType = JSON.stringify(project.projectType);

        if(!projectTypes.includes(projectType)) {
          projectTypes.push(projectType);
        }

        const index = projectTypes.indexOf(projectType);
        projectsArr[index] = Array.isArray(projectsArr[index]) ? [...projectsArr[index], project] : [project];
      }

      setProjects(projectsArr);
      setLoading(false);
    }
  }, [projectList]);

  return (
    <div className="container-fluid p-0 mt-3">
      {!loading && (
        <div className="list">
          {projects.map(projectsArr => (
            <SideBarHeader projects={projectsArr}/>
          ))}
        </div>
      )}
    </div>
  );
};

export default SideBar;
