import React, { useState, useEffect, Fragment } from "react";
import axios from "../../constants/axiosRequest";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ElnCard from "../layout/ElnCard";
import SearchField from "../layout/SearchField";
import Pagination from "../layout/Pagination";
import paginate from "../../utils/paginate";

const Search = ({ user }) => {
	const [datas, setDatas] = useState([]);
	const [search, setSearch] = useState([]);
	const [page, setPage] = useState(0);
	const [instruments, setInstruments] = useState([]);
	const [tests, setTests] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			const experiments = await axios.get(
				`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/search/experiments`
			);
			const pages = paginate(experiments.data);
			const userId = user.UserID;
			const instruments = await axios.get(
				`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/instruments/` +
					userId
			);
			const tests = await axios.get(
				`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/tests/` +
					userId
			);

			setInstruments(instruments.data);
			setTests(tests.data);
			setDatas(experiments.data);
			setSearch(pages);
			setLoading(false);
		};

		fetchData();
	}, [user]);

	const handlePage = (page) => {
		setPage(page);
	};

	const handleSearch = async (queryParam, query, filter) => {
		let pages;
		if (filter === "All") {
			const res = datas.filter((data) =>
				data[queryParam].toLowerCase().includes(query.toLowerCase())
			);
			pages = paginate(res);
		} else if (filter === "Not published") {
			const res = datas.filter(
				(data) =>
					data[queryParam]
						.toLowerCase()
						.includes(query.toLowerCase()) &&
					data.isPublished === false
			);
			pages = paginate(res);
		} else if (filter === "Published") {
			const res = datas.filter(
				(data) =>
					data[queryParam]
						.toLowerCase()
						.includes(query.toLowerCase()) &&
					data.isPublished === true
			);
			pages = paginate(res);
		} else if (filter === "Not reviewed") {
			const res = datas.filter(
				(data) =>
					data[queryParam]
						.toLowerCase()
						.includes(query.toLowerCase()) &&
					data.isPublished === true &&
					data.isReviewed === false
			);
			pages = paginate(res);
		} else {
			const res = datas.filter(
				(data) =>
					data[queryParam]
						.toLowerCase()
						.includes(query.toLowerCase()) &&
					data.isReviewed === true
			);
			pages = paginate(res);
		}
		setSearch(pages);
	};

	return (
		<Fragment>
			{!loading && (
				<div className="container-fluid white-background">
					<div className="row">
						<div className="col-12 workspace">
							<nav className="navbar navbar-expand-xl navbar-light">
								<button
									className="navbar-toggler"
									type="button"
									data-toggle="collapse"
									data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded="false"
									aria-label="Toggle navigation"
								>
									<span className="navbar-toggler-icon"></span>
								</button>
								<div
									className="collapse navbar-collapse"
									id="navbarSupportedContent"
								>
									<ul className="navbar-nav ml-auto">
										<li className="nav-item mr-3">
											{" "}
											<Link
												to="/projects"
												className="sub-nav-item link"
											>
												<i className="fas fa-project-diagram" />{" "}
												Projects
											</Link>
										</li>
										<li className="nav-item mr-3">
											<Link
												to="/templates"
												className="sub-nav-item link"
											>
												<i className="fas fa-copy" />{" "}
												Templates
											</Link>
										</li>
										<li className="nav-item mr-4">
											<Link
												to="/logs"
												className="sub-nav-item link"
											>
												<i className="fas fa-list" />{" "}
												Activity Log
											</Link>
										</li>
										<li className="nav-item">
											<SearchField
												useFilter={true}
												handleSearch={(
													queryParam,
													query,
													filter
												) =>
													handleSearch(
														queryParam,
														query,
														filter
													)
												}
											/>
										</li>
									</ul>
								</div>
							</nav>
							<div className="row">
								{search[page].map((data) => (
									<ElnCard
										data={data}
										instruments={instruments}
										tests={tests}
										searchQuery={0}
									/>
								))}
							</div>
							{search.length > 1 && (
								<Pagination
									data={search}
									handlePage={(page) => handlePage(page)}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(Search);
