import React, { useState, useEffect } from "react";
import DropdownSelector from "./DropdownSelector";

const DropdownLink = ({ linkTitle, selectionsArray, handleClick, sendRef }) => {
	const [dropdown, setDropdown] = useState(false);

	useEffect(() => {
		const handler = (event) => {
			if (dropdown && sendRef.current && !sendRef.current.contains(event.target)) {
				setDropdown(false);
			}
		};

		document.addEventListener("mousedown", handler);
		document.addEventListener("touchstart", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
			document.removeEventListener("touchstart", handler);
		};
	}, [dropdown]);

	return (
		<>
			<button className="navbar-link nav-link nav-text" onClick={() => setDropdown(!dropdown)}>
				<i className="fas fa-globe" /> {linkTitle}
			</button>
			<DropdownSelector subSelections={selectionsArray} dropdown={dropdown} handleClick={handleClick} />
		</>
	);
};

export default DropdownLink;
