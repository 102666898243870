import React from "react";

const DropdownSelector = ({ subSelections, dropdown, handleClick }) => {
    return (
        <ul className={`dropdown-selector ${dropdown ? "show" : ""}`}>
            {subSelections.map((subSelection, index) => (
                <li key={index}>
                    <button className="navbar-sublink" onClick={(e) => handleClick(e)} value={subSelection.value}>{subSelection.title}</button>
                </li>
            ))}
        </ul>
    )
}

export default DropdownSelector;