import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../constants/axiosRequest";
import ProjectsModal from "../layout/ProjectsModal";
import ProjectsCard from "../layout/ProjectsCard";
import SearchField from "../layout/SearchField";
import SideBar from "../layout/SideBar";
import Pagination from "../layout/Pagination";
import PopupModal from "../layout/PopupModal";
import Spinner from "../layout/Spinner";
import paginate from "../../utils/paginate";
import { getProjects, getProjectTypes } from "../../utils/apiCall";

const Project = ({ loginText, displayLoginText, user, useRoles, location }) => {
	const [projects, setProjects] = useState([]);
	const [projectTypes, setProjectTypes] = useState([]);
	const [data, setData] = useState([]);
	const [instruments, setInstruments] = useState([]);
	const [page, setPage] = useState(0);
	const [showInactive, setShowInactive] = useState(false);
	const [searchQueryParam, setSearchQueryParam] = useState("title");
	const [searchQuery, setSearchQuery] = useState("");
	const [loading, setLoading] = useState(true);
	const [messageDisplayed, setMessageDisplayed] = useState(true);
	const [updateState, setUpdateState] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			const externalUserId = user.externalUserId ? user.externalUserId : -100;
			let fetchProjects = await getProjects();
			setData(fetchProjects);

			if (fetchProjects) {
				fetchProjects = fetchProjects.filter((project) => (showInactive && project ? !project.active : project.active));
				const pages = paginate(fetchProjects.reverse());
				const instruments = await axios.get(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/instruments/` + externalUserId);
				const roleIds = user.roles ? user.roles.map((role) => role.id) : [];

				let projectTypeArr = [];

				if (useRoles && useRoles === "true" && !roleIds.includes(-2)) {
					for (let i = 0; i < roleIds.length; i++) {
						const role = user.roles[i];
						projectTypeArr.push(...role.projectTypes);
					}
				} else {
					projectTypeArr = await getProjectTypes();
				}

				let pageNumber = page;

				for (let i = 0; i < pageNumber; i++) {
					if (!pages[pageNumber] && pageNumber > 0) {
						pageNumber--;
					} else {
						break;
					}
				}

				if (!localStorage.getItem("messageDisplayed")) {
					localStorage.setItem("messageDisplayed", true);
					setMessageDisplayed(false);
				}

				setPage(pageNumber);
				setInstruments(instruments.data);
				setProjects(pages);
				setProjectTypes(projectTypeArr);
			}

			setLoading(false);
		};
		fetchData();
	}, [updateState, user, page, showInactive, useRoles, location]);

	const handlePage = (page) => {
		setPage(page);
	};

	const handleSearch = async (queryParam, query, inactive) => {
		if (data) {
			let res = data.filter((project) => project[queryParam].toLowerCase().includes(query.toLowerCase()));
			res = res.filter((project) => (typeof inactive === "boolean" ? (inactive ? !project.active : project.active) : showInactive ? !project.active : project.active));
			const pages = paginate(res.reverse());
			let pageNum = page;

			for (let i = 0; i < pageNum; i++) {
				if (!pages[pageNum] && pageNum > 0) {
					pageNum--;
				} else {
					break;
				}
			}

			setPage(pageNum);
			setSearchQueryParam(queryParam);
			setSearchQuery(query);
			setProjects(pages);
			return res;
		}

		return [];
	};

	const showInactiveProjects = async (e) => {
		e.preventDefault();
		const displayInactive = !showInactive;
		const searchData = await handleSearch(searchQueryParam, searchQuery, displayInactive);
		const res = searchData.filter((project) => (displayInactive ? !project.active : project.active));
		const pages = paginate(res);

		setPage(0);
		setShowInactive(!showInactive);
		setProjects(pages);
	};

	return (
		<Fragment>
			{loading && <Spinner />}
			{!loading && (
				<div className="container-fluid white-background">
					<PopupModal show={!messageDisplayed ? (displayLoginText === "true" ? true : false) : false} title="Welcome" body={<p>{loginText}</p>} />
					<div className="row">
						<div className="col-lg-2 hidden-md">
							<div className="side-bar">
								<SideBar projectList={[].concat.apply([], projects)} />
							</div>
						</div>
						<div className="col-12 col-lg-10 workspace">
							<div className="container-fluid">
								<nav className="navbar navbar-expand-xl navbar-light">
									<h3>
										<i className="fas fa-project-diagram" />
										<span> {showInactive ? "Inactive" : "Active"} Projects</span>
									</h3>
									<button
										className="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation">
										<span className="navbar-toggler-icon"></span>
									</button>
									<div className="collapse navbar-collapse" id="navbarSupportedContent">
										<ul className="navbar-nav mr-auto ml-auto">
											<li className="nav-item">
												{projectTypes.length > 0 && (
													<ProjectsModal edit={false} instruments={instruments} projectTypes={projectTypes} updateState={() => setUpdateState(!updateState)} />
												)}
											</li>
										</ul>
										<ul className="navbar-nav">
											<li className="nav-item mr-3">
												<button className="anchor-link" onClick={(e) => showInactiveProjects(e)}>
													Show {showInactive ? "Active" : "Inactive"} Projects
												</button>
											</li>
											<li className="nav-item mr-3">
												<Link to="/templates" className="link">
													<i className="fas fa-copy" />
													<span> Templates</span>
												</Link>
											</li>
											<li className="nav-item mr-3">
												<Link to="/search" className="link">
													<i className="fas fa-search" />
													<span> Search</span>
												</Link>
											</li>
											<li className="nav-item mr-4">
												<Link to="/logs" className="link">
													<i className="fas fa-list" />
													<span> Activity Log</span>
												</Link>
											</li>
											<li className="nav-item">
												<SearchField useFilter={false} handleSearch={(queryParam, query) => handleSearch(queryParam, query)} />
											</li>
										</ul>
									</div>
								</nav>
								<div className="row">
									{projects.length > 0 ? (
										projects[page].map((project) => (
											<ProjectsCard user={user} data={project} projects={[].concat.apply([], projects)} updateState={() => setUpdateState(!updateState)} />
										))
									) : (
										<h1> No Projects Found...</h1>
									)}
								</div>
								{projects.length > 1 && <Pagination data={projects} handlePage={(page) => handlePage(page)} />}
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	loginText: state.config.loginText,
	displayLoginText: state.config.displayLoginText,
	user: state.auth.user,
	useRoles: state.config.useRoles,
	location: state.location.currentLocation,
});

export default connect(mapStateToProps)(Project);
