import React, { Fragment, useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/workspace/Landing";
import Login from "./components/auth/Login";
import Alert from "./components/layout/Alert";
import Spinner from "./components/layout/Spinner";
import LimsLogin from "./components/workspace/LimsLogin";
import AdminPanel from "./components/workspace/AdminPanel";
import OpensocialAdmin from "./components/workspace/OpensocialAdmin";
import ErrorBoundary from "./components/error/ErrorBoundary";
import PasswordReset from "./components/workspace/PasswordReset";
import Project from "./components/workspace/Project";
import Experiment from "./components/workspace/Experiment";
import Notebook from "./components/workspace/Notebook";
import Template from "./components/workspace/Template.js";
import Search from "./components/workspace/Search.js";
import Log from "./components/workspace/Log";
import LogoutPopup from "./components/layout/LogoutPopup";
import TemplateBuilder from "./components/workspace/TemplateBuilder";
import NotAuthorized from "./components/error/NotAuthorized";
import NotFound from "./components/error/NotFound";
import { loadUser } from "./actions/auth";
import { getConfiguration, getFileConfiguration } from "./actions/config";
import { loadProjectTypes } from "./actions/projectType";
import { loadExperimentTypes } from "./actions/experimentType";
import { loadUserLocations } from "./actions/location";
import setAuthToken from "./utils/setAuthToken";
import "./App.css";

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const App = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetch = async () => {
			await store.dispatch(loadUser());

			if (store.getState().auth.isAuthenticated) {
				await store.dispatch(loadProjectTypes());
				await store.dispatch(loadExperimentTypes());
				await store.dispatch(loadUserLocations());
			}

			await store.dispatch(getConfiguration("clientName"));
			await store.dispatch(getConfiguration("loginText"));
			await store.dispatch(getConfiguration("displayLoginText"));
			await store.dispatch(getConfiguration("useRoles"));
			await store.dispatch(getConfiguration("selectedLocationEvent"));
			await store.dispatch(getConfiguration("selectedUserEvent"));
			await store.dispatch(getConfiguration("useOpenSocial"));
			await store.dispatch(getConfiguration("limsModule"));
			await store.dispatch(getFileConfiguration("logo"));
			setLoading(false);
		};
		fetch();
	}, []);

	return (
		<Provider store={store}>
			<Router>
				{loading && <Spinner />}
				{!loading && (
					<Fragment>
						<Navbar />
						<ErrorBoundary>
							<section>
								<Alert />
								<LogoutPopup />
								<Routes>
									<Route exact path="/" element={<Landing />} />
									<Route exact path="/limslogin" element={<LimsLogin />} />
									<Route exact path="/login" element={<Login />} />
									<Route exact path="/passwordReset" element={<PasswordReset />} />
									<Route exact path="/admin" element={<PrivateRoute />}>
										<Route exact path="/admin" element={<AdminPanel />} />
									</Route>
									<Route exact path="/opensocial" element={<PrivateRoute />}>
										<Route exact path="/opensocial" element={<OpensocialAdmin />} />
									</Route>
									<Route exact path="/projects" element={<PrivateRoute />}>
										<Route exact path="/projects" element={<Project />} />
									</Route>
									<Route exact path="/projects/:projectId/experiments" element={<PrivateRoute />}>
										<Route exact path="/projects/:projectId/experiments" element={<Experiment />} />
									</Route>
									<Route exact path="/projects/:projectId/experiments/:experimentId" element={<PrivateRoute />}>
										<Route exact path="/projects/:projectId/experiments/:experimentId" element={<Notebook />} />
									</Route>
									<Route exact path="/logs" element={<PrivateRoute />}>
										<Route exact path="/logs" element={<Log />} />
									</Route>
									<Route exact path="/templates" element={<PrivateRoute />}>
										<Route exact path="/templates" element={<Template />} />
									</Route>
									<Route exact path="/templates/:templateId" element={<PrivateRoute />}>
										<Route exact path="/templates/:templateId" element={<TemplateBuilder />} />
									</Route>
									<Route exact path="/search" element={<PrivateRoute />}>
										<Route exact path="/search" element={<Search />} />
									</Route>
									<Route exact path="/notauthorized" element={<NotAuthorized />} />
									<Route path="*" element={<NotFound />} />
								</Routes>
							</section>
						</ErrorBoundary>
					</Fragment>
				)}
			</Router>
		</Provider>
	);
};

export default App;
