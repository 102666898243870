import React from "react";
import { DocumentEditor } from "@onlyoffice/document-editor-react";

const DocEditor = ({ config }) => {
	const onlyOfficeUrl = window._env_.REACT_APP_ONLYOFFICE_HOSTNAME;

	const onLoadComponentError = (errorCode, errorDescription) => {};

	return (
		<div className="editor-wrapper">
			<DocumentEditor id={"documentEditor"} documentServerUrl={onlyOfficeUrl} config={config} onLoadComponentError={onLoadComponentError} />
		</div>
	);
};
export default DocEditor;
