import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Container } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { usersExist, createFirstUser } from "../../utils/apiCall";
import ModalButton from "../layout/ModalButton";
import LoadingModal from "../layout/LoadingModal";
import { setAlert } from "../../actions/alert";
import placeholder from "../../img/Lablynx-logo.png";

const Login = ({ login, isAuthenticated, logo, setAlert }) => {
	const [formData, setFormData] = useState({
		LoginID: "",
		Password: "",
	});
	const [userFormData, setUserFormData] = useState({
		username: "",
		password: "",
		confirmPassword: "",
		firstName: "",
		lastName: "",
		email: "",
	});
	const [displayButton, setDisplayButton] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);

	useEffect(() => {
		const checkData = async () => {
			const userExists = await usersExist();

			if (!userExists) {
				setDisplayButton(true);
			}
		};

		checkData();
	}, []);

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();
		login(formData.LoginID, formData.Password);
	};

	const onFirstUserChange = (e) => setUserFormData({ ...userFormData, [e.target.name]: e.target.value });

	const onFirstUserSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);
			await createFirstUser(userFormData);
			setDisplayButton(false);
			await setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	if (isAuthenticated) {
		return <Navigate to="/projects" />;
	}

	return (
		<div className="container-fluid">
			<LoadingModal modalShow={loadingModalShow} />
			<div className="row justify-content-center logo">
				<div className="col- shadow bg-white rounded panel text-center">
					<span>
						<img src={logo ? `data:image/jpeg;base64, ${logo}` : placeholder} alt="Client Logo" className="img-fluid" />
					</span>
					<form className="form mt-5" onSubmit={(e) => onSubmit(e)}>
						<div className="form-group">
							<input type="text" placeholder="Username" name="LoginID" value={formData.LoginID} onChange={(e) => onChange(e)} required />
						</div>
						<div className="form-group">
							<input type="password" placeholder="Password" name="Password" minLength="6" value={formData.Password} onChange={(e) => onChange(e)} required />
						</div>
						<div className="container row justify-content-center m-0 p-0">{!displayButton && <input type="submit" className="btn btn-primary login-btn" value="Login" />}</div>
					</form>
					<div className="container row justify-content-center m-0 p-0">
						{displayButton && (
							<ModalButton
								button={"Create Admin User"}
								title={"Create Admin User"}
								body={
									<>
										<Form onSubmit={(e) => onFirstUserSubmit(e)}>
											<Form.Group controlId="formUsername">
												<Form.Label>Username</Form.Label>
												<Form.Control required type="text" name="username" placeholder="Enter Username" value={userFormData.username} onChange={(e) => onFirstUserChange(e)} />
											</Form.Group>
											<Form.Group controlId="formFirstName">
												<Form.Label>First Name</Form.Label>
												<Form.Control
													required
													type="text"
													name="firstName"
													placeholder="Enter First Name"
													value={userFormData.firstName}
													onChange={(e) => onFirstUserChange(e)}
												/>
											</Form.Group>
											<Form.Group controlId="formLastName">
												<Form.Label>Last Name</Form.Label>
												<Form.Control required type="text" name="lastName" placeholder="Enter Last Name" value={userFormData.lastName} onChange={(e) => onFirstUserChange(e)} />
											</Form.Group>
											<Form.Group controlId="formEmail">
												<Form.Label>Email</Form.Label>
												<Form.Control required type="text" name="email" placeholder="Enter Email" value={userFormData.email} onChange={(e) => onFirstUserChange(e)} />
											</Form.Group>
											<Form.Group controlId="formPassword">
												<Form.Label>Password</Form.Label>
												<Form.Control
													required
													type="password"
													name="password"
													placeholder="Enter Password"
													value={userFormData.password}
													onChange={(e) => onFirstUserChange(e)}
												/>
											</Form.Group>
											<Form.Group controlId="formConfirmPassword">
												<Form.Label>Confirm Password</Form.Label>
												<Form.Control
													required
													type="password"
													name="confirmPassword"
													placeholder="Confirm Password"
													value={userFormData.confirmPassword}
													onChange={(e) => onFirstUserChange(e)}
												/>
											</Form.Group>
											<Container>
												<Button variant="primary" type="submit">
													{"Create"}
												</Button>
											</Container>
										</Form>
									</>
								}
								modalShow={modalShow}
								setModalShow={(bool) => setModalShow(bool)}
								onButtonClick={() => setModalShow(true)}
								variant={"primary"}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

Login.propTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	logo: state.config.logo,
});

export default connect(mapStateToProps, { login, setAlert })(Login);
