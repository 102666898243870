const paginate = data => {
  let pages = [];
  let page = [];

  if (data.length > 12) {

    for (const element of data) {
      page.push(element);

      if (page.length === 12) {
        pages.push(page);
        page = [];
      }
    }

    if (page.length > 0) {
      pages.push(page);
    }
  } else {
    pages.push(data);
  }

  return pages;
};

export default paginate;
