import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Tabs from "../layout/Tabs";
import Config from "./tabs/AdminPanel/Config";
import Types from "./tabs/AdminPanel/Types";
import ExperimentTypes from "./tabs/AdminPanel/ExperimentTypes";
import Roles from "./tabs/AdminPanel/Roles";
import Users from "./tabs/AdminPanel/Users";
import DataConnections from "./tabs/AdminPanel/DataConnections";
import Events from "./tabs/AdminPanel/Events";
import Locations from "./tabs/AdminPanel/Locations";
import { loadProjectTypes } from "../../actions/projectType";
import { loadExperimentTypes } from "../../actions/experimentType";

const AdminPanel = ({ user, useRoles, projectTypeLoading, loadProjectTypes, experimentTypeLoading, loadExperimentTypes }) => {
	const [roleActive, setRoleActive] = useState(useRoles === "true" ? true : false);
	const roles = user.roles;
	const roleIdArr = roles ? roles.map((role) => role.id) : [];

	if (projectTypeLoading) {
		loadProjectTypes();
	}

	if (experimentTypeLoading) {
		loadExperimentTypes();
	}

	return (
		<>
			{(roleIdArr.includes(-2) || !roleActive || user.UserID === -1) && (
				<div className="container-fluid white-background display-overflow max-display">
					<nav className="navbar navbar-expand-xl navbar-light">
						<h3>Admin Panel</h3>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav ml-auto">
								<li className="nav-item mr-3" key="projects">
									<Link to="/projects" className="sub-nav-item link">
										<i className="fas fa-project-diagram" /> Projects
									</Link>
								</li>
								<li className="nav-item mr-3" key="templates">
									<Link to="/templates" className="sub-nav-item link">
										<i className="fas fa-copy" /> Templates
									</Link>
								</li>
								<li className="nav-item mr-3" key="search">
									<Link to="/search" className="sub-nav-item link">
										<i className="fas fa-search" /> Search
									</Link>
								</li>
								<li className="nav-item" key="logs">
									<Link to="/logs" className="link">
										<i className="fas fa-list" />
										<span> Activity Log</span>
									</Link>
								</li>
							</ul>
						</div>
					</nav>
					<div className="container-fluid center-container">
						<Tabs user={user}>
							<Config label="Config" setRoleActive={(isActive) => setRoleActive(isActive)} />
							<Types label="Types" />
							<Roles label="Roles" />
							<Users label="Users" roleActive={roleActive} />
							<Locations label="Locations" />
							<ExperimentTypes label="Workbook Types" />
							<DataConnections label="Data Connections" />
							<Events label="Events" />
						</Tabs>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
	useRoles: state.config.useRoles,
	projectTypeLoading: state.projectType.loading,
	experimentTypeLoading: state.experimentType.loading,
});

export default connect(mapStateToProps, { loadProjectTypes, loadExperimentTypes })(AdminPanel);
