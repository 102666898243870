import { SET_TIMEZONE } from "../actions/types";

const initialState = {
    tzOption: 0
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_TIMEZONE:
      localStorage.setItem("tzOption", payload.tzOption)
      return payload;
    default:
      return state;
  }
}
