import React, { useState } from "react";

const Pagination = props => {
  const [page, setPage] = useState(0);

  const handlePage = page => {
    setPage(page);
    props.handlePage(page);
  };

  return (
    <nav className="bottom-fixed" aria-label="Page navigation">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            onClick={() => handlePage(page - 1)}
            disabled={page === 0}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {props.data.map((element, index) => (
          <li className={`page-item ${page === index ? "active" : ""}`}>
            <button className="page-link" onClick={() => handlePage(index)}>
              {index + 1}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            onClick={() => handlePage(page + 1)}
            disabled={page === props.data.length - 1}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
