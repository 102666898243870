import React, { useState, useEffect, Fragment } from "react";
import { ButtonGroup, ButtonToolbar, Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingModal from "./LoadingModal";
import { setAlert } from "../../actions/alert";
import { createExperiment, editExperiment, createLog } from "../../utils/apiCall";
import word from "../../img/Microsoft_Word_logo.png";
import excel from "../../img/288px-Microsoft_Excel_Logo.svg.png";

const ExperimentsModal = ({ user, experimentTypes, setAlert, ...props }) => {
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [formData, setFormData] = useState({
		title: props.data ? props.data.title : "",
		description: props.data ? props.data.description : "",
		testId: props.data ? props.data.TESTID : -2,
		instrumentId: props.data ? props.data.ISNTRUMENTID : -2,
		documentType: props.data ? props.data.documentType : "docx",
		visibility: props.data ? props.data.visibility : "Public",
		experimentTypeId: props.data ? props.data.experimentTypeId : experimentTypes[0] ? experimentTypes[0].id : "",
	});
	const [updateState, setUpdateState] = useState(true);

	const userName = `${user.FirstName} ${user.LastName}`;

	useEffect(() => {
		const fetchData = async () => {
			setFormData({
				title: props.data ? props.data.title : "",
				description: props.data ? props.data.description : "",
				testId: props.data ? props.data.TESTID : -2,
				instrumentId: props.data ? props.data.ISNTRUMENTID : -2,
				documentType: props.data ? props.data.documentType : "docx",
				visibility: props.data ? props.data.visibility : "Public",
				experimentTypeId: props.data ? props.data.experimentTypeId : experimentTypes[0] ? experimentTypes[0].id : "",
			});
		};

		fetchData();
	}, [updateState, props.data]);

	const { title, description, documentType, testId, instrumentId, visibility, experimentTypeId } = formData;
	const projectId = props.projectId;

	let buttonIcon = "plus";
	if (props.edit) {
		buttonIcon = "edit";
	}

	const onClick = () => {
		setUpdateState(!updateState);
		setModalShow(true);
	};

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const handleExperimentTypeChange = (e) => {
		setFormData({ ...formData, experimentTypeId: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			if (props.edit) {
				const experiment = await editExperiment(title, description, projectId, props.data.id, instrumentId, testId, userName, experimentTypeId);

				if (experiment !== undefined) {
					await createLog("Edit Workbook", `${user.FirstName} ${user.LastName} edited workbook ${title}`);
				}
			} else {
				if (props.project.projectTypeId !== -3) {
					const experiment = await createExperiment(title, description, documentType, projectId, instrumentId, testId, userName, visibility, experimentTypeId);

					if (experiment !== undefined) {
						await createLog("Create Workbook", `${user.FirstName} ${user.LastName} created workbook ${title}`);
					}
				} else {
					const experiment = await createExperiment(title, description, documentType, projectId, instrumentId, testId, userName, visibility, experimentTypeId);

					if (experiment !== undefined) {
						await createLog("Create Workbook", `${user.FirstName} ${user.LastName} created workbook ${title}`);
					}
				}
			}

			props.updateState();
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<ButtonToolbar>
			<LoadingModal modalShow={loadingModalShow} />
			{((!props.edit && experimentTypes.length > 0) ||
				experimentTypes
					.filter((e) => !e.useACL || e.RoleExperimentType.edit)
					.map((e) => e.id)
					.includes(formData.experimentTypeId)) && (
				<Button variant="primary" className="modal-button btn-sm" onClick={() => onClick()}>
					<i className={`fas fa-${buttonIcon}`} /> {props.edit ? "Edit" : <span> New Workbook</span>}
				</Button>
			)}
			<Modal {...props} show={modalShow} onHide={() => setModalShow(false)} size="md" aria-labelledby="form-modal" centered>
				<Modal.Header closeButton>
					<Modal.Title id="form-modal">
						<i className={`fas fa-${buttonIcon}`} /> {props.edit ? "Edit Workbook" : "New Workbook"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => onSubmit(e)}>
						<Form.Group controlId="formTitle">
							<Form.Label>Title</Form.Label>
							<Form.Control required type="text" name="title" value={formData.title} placeholder="Enter Title" onChange={(e) => onChange(e)} />
						</Form.Group>
						<Form.Group controlId="formExperimentType" className="mt-3">
							<Form.Label>Workbook Type</Form.Label>
							<Form.Control as="select" value={formData.experimentTypeId} onChange={(e) => handleExperimentTypeChange(e)}>
								{experimentTypes.map((experimentType) => (
									<option key={experimentType.id} value={experimentType.id}>
										{experimentType.typeName}
									</option>
								))}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="formDescription" className="mt-3">
							<Form.Label>Description</Form.Label>
							<Form.Control as="textarea" name="description" value={formData.description} placehoder="Enter Description" onChange={(e) => onChange(e)} />
						</Form.Group>
						{!props.edit && (
							<Fragment>
								<Form.Group controlId="formTest" className="mt-3">
									<Form.Label>Test</Form.Label>
									<Form.Control as="select" name="testId" value={formData.testId} onChange={(e) => onChange(e)}>
										<option value={-2}>N/A</option>
										{props.tests ? props.tests.map((test) => <option value={test.TESTID}>{test.TESTNAME}</option>) : ""}
									</Form.Control>
								</Form.Group>
								{props.project.type !== "Instrument" && (
									<Form.Group controlId="formInstrument" className="mt-3">
										<Form.Label>Instrument</Form.Label>
										<Form.Control as="select" name="instrumentId" value={formData.instrumentId} onChange={(e) => onChange(e)}>
											<option value={-2}>N/A</option>
											{props.instruments ? props.instruments.map((instrument) => <option value={instrument.INSTRUMENTID}>{instrument.INSTRUMENTNAME}</option>) : ""}
										</Form.Control>
									</Form.Group>
								)}
								<Form.Group controlId="formVisibility" className="mt-3">
									<Form.Label>Visibility</Form.Label>
									<Form.Control as="select" name="visibility" value={formData.visibility} onChange={(e) => onChange(e)}>
										<option value="Public">Public</option>
										<option value="Private">Private</option>
									</Form.Control>
								</Form.Group>
								<Form.Group controlId="formDocumentTypeButton" className="mt-3">
									<ButtonGroup aria-label="Document Type">
										<Button
											className="document-button"
											variant="secondary"
											onClick={() =>
												setFormData({
													...formData,
													documentType: "docx",
												})
											}>
											<span>Word</span>
											<br />
											<img className="icon" src={word} alt="icon" />
										</Button>
										<Button
											className="document-button"
											variant="secondary"
											onClick={() =>
												setFormData({
													...formData,
													documentType: "xlsx",
												})
											}>
											<span>Excel</span>
											<br />
											<img className="icon" src={excel} alt="icon" />
										</Button>
									</ButtonGroup>
								</Form.Group>
							</Fragment>
						)}
						<Container>
							<Row className="mt-3">
								<Col>
									<Button variant="primary" type="submit">
										{props.edit ? "Edit" : "Create"}
									</Button>
								</Col>
								<Col>
									<Button onClick={() => setModalShow(false)}>Close</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
			</Modal>
		</ButtonToolbar>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { setAlert })(ExperimentsModal);
