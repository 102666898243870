import React, { Fragment } from "react";
import NotFoundImg from "../../img/lablynx/404.png"
import logger from "../../utils/logger";
import {connect} from "react-redux"


const NotFound = ({user}) => {
  logger('error',{type: '404', href: window.location.href, user: user} )
  return (
    <Fragment>
            <img src={NotFoundImg} alt="Not Found" width="100%" height="600" />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(NotFound);
