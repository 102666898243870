import React from "react";
import { ButtonToolbar, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";

const ModalButton = ({
	button,
	title,
	body,
	modalShow,
	setModalShow,
	onButtonClick,
	variant,
}) => {
	return (
		<ButtonToolbar onClick={(e) => e.stopPropagation()}>
			<Button
				variant={variant ? variant : "primary"}
				className="modal-button btn-sm"
				onClick={(e) => onButtonClick(e)}
			>
				{button}
			</Button>
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="md"
				aria-labelledby="form-modal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="form-modal">{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{body}</Modal.Body>
			</Modal>
		</ButtonToolbar>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(ModalButton);
