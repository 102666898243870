import { PROJECT_TYPES_LOADED, PROJECT_TYPES_ERROR, PROJECT_TYPE_CREATED, PROJECT_TYPE_UPDATED } from "./types";
import { getProjectTypes, createProjectType, updateProjectType } from "../utils/apiCall";

export const loadProjectTypes = () => async (dispatch) => {
	try {
		const projectTypes = await getProjectTypes();

		dispatch({
			type: PROJECT_TYPES_LOADED,
			payload: projectTypes,
		});
	} catch (err) {
		dispatch({
			type: PROJECT_TYPES_ERROR,
		});
	}
};

export const createProjectTypeAction = (typeName, shortName, active) => async (dispatch) => {
	try {
		const projectType = await createProjectType(typeName, shortName, active);

		dispatch({
			type: PROJECT_TYPE_CREATED,
			payload: projectType,
		});

		dispatch(loadProjectTypes());
	} catch (err) {
		dispatch({
			type: PROJECT_TYPES_ERROR,
		});
	}
};

export const updateProjectTypeAction = (id, name, shortName, active) => async (dispatch) => {
	try {
		const projectTypes = await updateProjectType(id, name, shortName, active);

		dispatch({
			type: PROJECT_TYPE_UPDATED,
			payload: projectTypes,
		});

		dispatch(loadProjectTypes());
	} catch (err) {
		dispatch({
			type: PROJECT_TYPES_ERROR,
		});
	}
};
