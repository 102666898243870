import { getUserData } from "../utils/userData";

export const formatTimezone = (date, tzOption) => {
    if(!date) {
        return "";
    }

    const dateObject = new Date(date);
    let formattedDate = "";

    if(!tzOption) {
        formattedDate = dateObject.toLocaleString();
    } else {
        formattedDate = dateObject.toLocaleString("en-US", { timeZone: tzOption });
    }

    return formattedDate;
}

export const getTimezone = (timeZoneOption) => {
    let tzOption = localStorage.getItem("tzOption");

    if(!tzOption) {
        tzOption = timeZoneOption;
    }

    return tzOption;
}

export const hasTimezone = async () => {
    const user = await getUserData();
    let hasTz = false;

    if(user?.Timezone) {
        hasTz = true;
    }

    return hasTz;
}
