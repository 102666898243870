import React, { useState } from "react";
import { ButtonToolbar, Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingModal from "./LoadingModal";
import { setAlert } from "../../actions/alert";
import { createLog } from "../../utils/apiCall";

const FileUploadModal = ({ user, setAlert, ...props }) => {
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	// let uploadedFiles = [];
	const userName = `${user.FirstName} ${user.LastName}`;

	const onChange = (e) => {
		setUploadedFiles(e.target.files);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoadingModalShow(true);
			const data = new FormData();

			for (let i = 0; i < uploadedFiles.length; i++) {
				data.append("files", uploadedFiles[i]);
			}

			const res = await props.uploadFiles(data, userName);

			if (res !== undefined) {
				let message = " ";

				if (uploadedFiles.length > 1) {
					message = "s ";
				} else {
					message = " ";
				}

				let fileNameArr = Array.from(uploadedFiles).map((file) => file.name);

				if (props.logFlag === 0) {
					await createLog("Upload Workbook", `${user.FirstName} ${user.LastName} uploaded workbook${message + fileNameArr.join(",")}`);
				} else if (props.logFlag === 1) {
					await createLog("Upload Template", `${user.FirstName} ${user.LastName} uploaded template${message + fileNameArr.join(",")}`);
				}
			}

			props.updateState();
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<ButtonToolbar>
			<LoadingModal modalShow={loadingModalShow} />
			<Button variant="primary" className="modal-button btn-sm" onClick={() => setModalShow(true)}>
				<i className="fas fa-upload" />
				<span> Upload Files</span>
			</Button>
			<Modal {...props} show={modalShow} onHide={() => setModalShow(false)} size="md" aria-labelledby="form-modal" centered>
				<Modal.Header closeButton>
					<Modal.Title id="form-modal">
						<i className="fas fa-upload" /> Upload Files
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => onSubmit(e)} enctype="multipart/form-data">
						<Form.Group controlId="formDocumentType">
							<Form.Control id="file-upload" type="file" multiple onChange={(e) => onChange(e)} />
						</Form.Group>
						<Form.Group controlId="formDocumentTypeButton" className="mt-3">
							<Button variant="primary" type="submit" onClick={() => setModalShow(false)}>
								Submit
							</Button>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
		</ButtonToolbar>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { setAlert })(FileUploadModal);
