import React, { Fragment, useState, useEffect } from "react";
import axios from "../../constants/axiosRequest";
import placeholder from "../../img/Lablynx-logo.png";
import { setOpensocialConfig, getOpensocialConfig } from "../../utils/apiCall";
import logger from "../../utils/logger";

const OpensocialAdmin = () => {
	const [formData, setFormData] = useState({
		redirectUrl: "",
		spEntityId: "",
		xmlAttribute: "",
		ssoId: "",
		apiId: "",
		siteUrl: "",
	});
	const [logo, setLogo] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			const logo = await axios.get(
				`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/config/logo`
			);
			const opensocialConfig = await getOpensocialConfig();

			if (logo) {
				setLogo(logo.data);
			}

			if (opensocialConfig) {
				setFormData({
					redirectUrl: opensocialConfig.data.redirectUrl,
					spEntityId: opensocialConfig.data.spEntityId,
					xmlAttribute: opensocialConfig.data.xmlAttribute,
					ssoId: opensocialConfig.data.ssoId,
					apiId: opensocialConfig.data.apiId,
					siteUrl: opensocialConfig.data.siteUrl,
				});
			}

			setLoading(false);
		};

		fetchData();
	}, []);

	const { redirectUrl, spEntityId, xmlAttribute, ssoId, apiId, siteUrl } =
		formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		try {
			e.preventDefault();
			await setOpensocialConfig(formData);
		} catch (err) {
			logger("error", err);
		}
	};

	return (
		<Fragment>
			{!loading && (
				<div className="container-fluid">
					<div className="row justify-content-center logo">
						<div className="col- shadow bg-white rounded panel text-center">
							<span>
								<img
									src={logo ? logo : placeholder}
									alt="Client Logo"
									className="img-fluid"
								/>
							</span>
							<form
								className="form mt-5"
								onSubmit={(e) => onSubmit(e)}
							>
								<div className="form-group row p-0">
									<div class="col-">
										<strong>
											<label for="redirectUrl">
												Redirect URL:{" "}
											</label>
										</strong>
									</div>
									<div class="col- ml-auto">
										<input
											type="text"
											placeholder="Redirect URL"
											name="redirectUrl"
											value={redirectUrl}
											onChange={(e) => onChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group row p-0">
									<div class="col-">
										<strong>
											<label for="spEntityId">
												SP Entity ID:{" "}
											</label>
										</strong>
									</div>
									<div class="col- ml-auto">
										<input
											type="text"
											placeholder="SP Entity ID"
											name="spEntityId"
											value={spEntityId}
											onChange={(e) => onChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group row p-0">
									<div class="col-">
										<strong>
											<label for="xmlAttribute">
												Xml Attribute:{" "}
											</label>
										</strong>
									</div>
									<div class="col- ml-auto">
										<input
											type="text"
											placeholder="Xml Attribute"
											name="xmlAttribute"
											value={xmlAttribute}
											onChange={(e) => onChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group row p-0">
									<div class="col-">
										<strong>
											<label for="ssoId">SSO ID: </label>
										</strong>
									</div>
									<div class="col- ml-auto">
										<input
											type="text"
											placeholder="SSO ID"
											name="ssoId"
											value={ssoId}
											onChange={(e) => onChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group row p-0">
									<div class="col-">
										<strong>
											<label for="apiId">API ID: </label>
										</strong>
									</div>
									<div class="col- ml-auto">
										<input
											type="text"
											placeholder="API ID"
											name="apiId"
											value={apiId}
											onChange={(e) => onChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group row p-0">
									<div class="col-">
										<strong>
											<label for="siteUrl">
												Site URL:{" "}
											</label>
										</strong>
									</div>
									<div class="col- ml-auto">
										<input
											type="text"
											placeholder="Site URL"
											name="siteUrl"
											value={siteUrl}
											onChange={(e) => onChange(e)}
											required
										/>
									</div>
								</div>
								<div className="container row justify-content-center m-0 p-0">
									<input
										type="submit"
										className="btn btn-primary login-btn"
										value="Save to Opensocial"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default OpensocialAdmin;
