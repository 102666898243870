import React, { useState } from "react";
import Tab from "./Tab";

const Tabs = ({ children, user }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const onClickTab = tab => {
        setActiveTab(tab);
    }

    return (
        <div className="tabs">
            <ol className="tab-list">
                {children.map(child => {
                    if(!child.props) {
                        return false
                    }
                    const { label } = child.props;

                    if(label === "Opensocial" && user.UserID !== -1 && user.UserID !== 0) {
                        return false
                    }

                    return (
                        <Tab
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClick={onClickTab}
                        />
                    )
                })}
            </ol>
            <div className="tab-content">
                {children.map((child) => {
                    if (child.props && child.props.label !== activeTab) return undefined;
                    return child;
                })}
            </div>
        </div>
    )
}

export default Tabs;