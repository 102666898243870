import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import Tabs from "../../../layout/Tabs";
import ModalButton from "../../../layout/ModalButton";
import LoadingModal from "../../../layout/LoadingModal";
import Spinner from "../../../layout/Spinner";
import ClickableCard from "../../../layout/ClickableCard";
import ToggleButton from "../../../layout/ToggleButton";
import { setAlert } from "../../../../actions/alert";
import { setConfiguration } from "../../../../actions/config";
import { getAllDataConnections, getAllEvents, getAllEventTypes, updateEvent } from "../../../../utils/apiCall";

const Events = ({ user, setAlert, selectedLocationEvent, selectedUserEvent, setConfiguration }) => {
	const [dataConnections, setDataConnections] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const dataConnections = await getAllDataConnections();
		setDataConnections(dataConnections);
		setLoading(false);
	};

	return (
		<>
			{loading && <Spinner />}
			{!loading && (
				<div className="container-fluid">
					{dataConnections.length > 0 && (
						<Tabs user={user}>
							{dataConnections.map((dataConnection) => (
								<EventsTabs
									key={dataConnection.name}
									label={dataConnection.name}
									dataConnection={dataConnection}
									setAlert={setAlert}
									selectedLocationEvent={selectedLocationEvent}
									selectedUserEvent={selectedUserEvent}
									setConfiguration={setConfiguration}
								/>
							))}
						</Tabs>
					)}
				</div>
			)}
		</>
	);
};

const EventsTabs = ({ setAlert, dataConnection, selectedLocationEvent, selectedUserEvent, setConfiguration }) => {
	const [events, setEvents] = useState([]);
	const [eventTypes, setEventTypes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingModalShow, setLoadingModalShow] = useState(false);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setEvents(await getAllEvents(dataConnection.id));
		setEventTypes(await getAllEventTypes());
		setLoading(false);
	};

	return (
		<>
			<LoadingModal modalShow={loadingModalShow} />
			<ul className="no-bullets p-0">
				{loading && <Spinner />}
				{!loading &&
					events.map((event) => {
						return (
							<NestedList
								setAlert={setAlert}
								setLoadingModalShow={setLoadingModalShow}
								dataConnection={dataConnection}
								event={event}
								setEvents={setEvents}
								eventTypes={eventTypes}
								selectedLocationEvent={selectedLocationEvent}
								selectedUserEvent={selectedUserEvent}
								setConfiguration={setConfiguration}
							/>
						);
					})}
			</ul>
		</>
	);
};

const NestedList = ({ setAlert, setLoadingModalShow, dataConnection, event, setEvents, eventTypes, selectedLocationEvent, selectedUserEvent, setConfiguration }) => {
	const [editModalShow, setEditModalShow] = useState(false);
	const [checkboxData, setCheckboxData] = useState({
		selectedLocationEvent: selectedLocationEvent === `${event.dataConnectionId}` ? true : false,
		selectedUserEvent: selectedUserEvent === `${event.dataConnectionId}` ? true : false,
	});

	const eventType = eventTypes.filter((eventType) => eventType.id === event.eventTypeId)[0];

	const onEditClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setEditModalShow(bool);
	};

	const handleCheckboxClick = async (e) => {
		setCheckboxData({ ...checkboxData, [e.target.name]: e.target.checked.toString() });

		if (e.target.checked) {
			await setConfiguration(e.target.name, event.dataConnectionId);
		} else {
			await setConfiguration(e.target.name, 0);
		}
	};

	return (
		<li key={event.id}>
			{(event.eventTypeId === 4 && (
				<Row>
					<Col className="col-10">
						<EventCard
							setAlert={setAlert}
							setLoadingModalShow={setLoadingModalShow}
							setEditModalShow={setEditModalShow}
							editModalShow={editModalShow}
							onEditClick={onEditClick}
							dataConnection={dataConnection}
							event={event}
							setEvents={setEvents}
							eventType={eventType}
						/>
					</Col>
					<Col className="mt-4">
						<ToggleButton title={event.name} name="selectedLocationEvent" onClick={(e) => handleCheckboxClick(e)} defaultChecked={checkboxData.selectedLocationEvent} />
					</Col>
				</Row>
			)) ||
				(event.eventTypeId === 5 && (
					<Row>
						<Col className="col-10">
							<EventCard
								setAlert={setAlert}
								setLoadingModalShow={setLoadingModalShow}
								setEditModalShow={setEditModalShow}
								editModalShow={editModalShow}
								onEditClick={onEditClick}
								dataConnection={dataConnection}
								event={event}
								setEvents={setEvents}
								eventType={eventType}
							/>
						</Col>
						<Col className="mt-4">
							<ToggleButton title={event.name} name="selectedUserEvent" onClick={(e) => handleCheckboxClick(e)} defaultChecked={checkboxData.selectedUserEvent} />
						</Col>
					</Row>
				)) || (
					<EventCard
						setAlert={setAlert}
						setLoadingModalShow={setLoadingModalShow}
						setEditModalShow={setEditModalShow}
						editModalShow={editModalShow}
						onEditClick={onEditClick}
						dataConnection={dataConnection}
						event={event}
						setEvents={setEvents}
						eventType={eventType}
					/>
				)}
		</li>
	);
};

const EditForm = ({ id, name, endpoint, setEditModalShow, setLoadingModalShow, dataConnection, setEvents, setAlert }) => {
	const [formData, setFormData] = useState({
		id,
		name,
		endpoint,
	});

	const onEditChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onEditSubmit = async (e) => {
		e.preventDefault();
		setEditModalShow(false);
		setLoadingModalShow(true);

		try {
			const { endpoint } = formData;
			await updateEvent(dataConnection.id, id, endpoint);
			setEvents(await getAllEvents(dataConnection.id));
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<Form onSubmit={(e) => onEditSubmit(e)}>
			<Form.Group controlId="formEditBaseUrl">
				<Form.Label>Endpoint</Form.Label>
				<Form.Control required type="text" name="endpoint" placeholder="Enter Endpoint" value={formData.endpoint} onChange={(e) => onEditChange(e)} />
			</Form.Group>
			<Container>
				<Button variant="primary" type="submit">
					{"Save"}
				</Button>
			</Container>
		</Form>
	);
};

const EventCard = ({ setAlert, setLoadingModalShow, setEditModalShow, editModalShow, onEditClick, dataConnection, event, setEvents, eventType }) => {
	return (
		<ClickableCard
			onClick={(e) => onEditClick(e)}
			body={
				<Row>
					<Col className="col-8">
						<span>
							<strong>{eventType.name}</strong>
							{event.endpoint ? ": " + event.endpoint : ""}
						</span>
					</Col>
					<Col className="col-2">
						<ModalButton
							button={
								<>
									<span>Edit </span>
									<i className={`fas fa-edit`} />
								</>
							}
							title={"Edit"}
							body={
								<EditForm
									id={event.id}
									name={eventType.name}
									endpoint={event.endpoint}
									dataConnection={dataConnection}
									setEditModalShow={(bool) => setEditModalShow(bool)}
									setLoadingModalShow={(bool) => setLoadingModalShow(bool)}
									setEvents={setEvents}
									setAlert={setAlert}
								/>
							}
							modalShow={editModalShow}
							setModalShow={(bool) => setEditModalShow(bool)}
							onButtonClick={(e) => onEditClick(e, true)}
						/>
					</Col>
				</Row>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
	selectedLocationEvent: state.config.selectedLocationEvent,
	selectedUserEvent: state.config.selectedUserEvent,
});

export default connect(mapStateToProps, { setAlert, setConfiguration })(Events);
