import { EXPERIMENT_TYPES_ERROR, EXPERIMENT_TYPE_CREATED, EXPERIMENT_TYPES_LOADED, EXPERIMENT_TYPE_UPDATED } from "../actions/types";

const initialState = {
  loading: true,
  experimentTypes: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EXPERIMENT_TYPES_LOADED:
        return {
            ...state,
            loading: false,
            experimentTypes: payload
          };
    case EXPERIMENT_TYPE_UPDATED:
    case EXPERIMENT_TYPE_CREATED:
      return {
        ...state,
        loading: true,
      };
    case EXPERIMENT_TYPES_ERROR:
        return {
            ...state
        }
    default:
      return state;
  }
}
