import React from "react";
import { Modal } from "react-bootstrap";
import Spinner from "./Spinner";

const LoadingModal = ({ modalShow }) => {
    return (
        <Modal show={modalShow} size="md" aria-labelledby="form-modal" centered>
            <Spinner/>
        </Modal>
    )
};

export default LoadingModal;