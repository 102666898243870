import React, { useState } from "react";
import SideBarItem from "./SideBarItem";

const SideBarHeader = ({ projects }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const iconName = isCollapsed ? "down" : "right";
  const toggleHidden = isCollapsed ? "" : "hidden";

  return (
    <> 
      {projects.length > 0 &&
      <>
        <div className="first-list-item">
          <span className={`clickable`}>
            <i className={`fas fa-caret-${iconName}`} onClick={() => setIsCollapsed(!isCollapsed)} />
          </span>
          <span className="header-text"> {projects[0].projectType.typeName}</span>
        </div>
        <div className={toggleHidden}>
          {projects.map(project => (
            <SideBarItem project={project}/>
          ))}
        </div>
      </>
      }
    </>
  );
};

export default SideBarHeader;
