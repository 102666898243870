import { GET_CONFIG, SET_CONFIG, GET_FILE_CONFIG, SET_FILE_CONFIG } from "../actions/types";

const initialState = {
    loading: true
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CONFIG:
        case SET_CONFIG:
        case GET_FILE_CONFIG:
        case SET_FILE_CONFIG:
            const key = Object.keys(payload)[0];
            const value = Object.values(payload)[0];
        
            localStorage.setItem(key, value)
        
            return { ...state, ...payload, loading: false };
        default:
        return state;
    }
}
