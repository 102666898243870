import React, { useState, useEffect, Fragment } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "../../constants/axiosRequest";
import DocEditor from "./DocEditor";
import LoadTemplateModal from "../layout/LoadTemplateModal";
import ModalButton from "../layout/ModalButton";
import Signature from "../layout/Signature";
import LoadingModal from "../layout/LoadingModal";
import {
	getProject,
	getProjects,
	publishNotebook,
	reviewNotebook,
	getNotebook,
	getTemplates,
	getExperimentKey,
	exportDocument,
	createLog,
	forceSaveDocument,
	getExperimentsPermissions,
	getExperimentTypes,
	getUser,} from "../../utils/apiCall";
import SideBar from "../layout/SideBar";
import { setAlert } from "../../actions/alert";
import { formatTimezone, getTimezone } from "../../utils/timezone";

const Notebook = ({ user, setAlert, limsModule }) => {
	const [experiment, setExperiment] = useState({});
	const [project, setProject] = useState({});
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [publisher, setPublisher] = useState("");
	const [isPublished, setIsPublished] = useState(false);
	const [reviewer, setReviewer] = useState("");
	const [isReviewed, setIsReviewed] = useState(false);
	const [templates, setTemplates] = useState([]);
	const [tzOption, setTzOption] = useState("");
	const [userName, setUserName] = useState("");
	const [experimentKey, setExperimentKey] = useState("");
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [exportModalShow, setExportModalShow] = useState(false);
	const [canEdit, setCanEdit] = useState([]);

	const { projectId, experimentId } = useParams();

	const url = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}`;

	const getDistinctExperimentTypes = async () => {
		const user = await getUser();
		const experimentTypes = await getExperimentTypes();
		const distinctExperimentTypes = [];

		experimentTypes.filter(et => !et.useACL).forEach(experimentType => {
			if (!distinctExperimentTypes.find(t => t.id == experimentType.id))
				distinctExperimentTypes.push(experimentType);
		});
		user.roles.forEach(role => {
			role.ExperimentTypes.forEach(experimentType => {
				if (!distinctExperimentTypes.find(t => t.id == experimentType.id))
					distinctExperimentTypes.push(experimentType);
			});
		});

		return distinctExperimentTypes.sort(function (a, b) {
			return a.id - b.id
		});
	};
	useEffect(() => {
		const fetchData = async () => {
			const project = await getProject(projectId);
			setProject(project);

			if (!project) {
				return;
			}

			const experiment = await getNotebook(projectId, experimentId);
			setExperiment(experiment);

			if (!experiment) {
				return;
			}

			const experimentKey = await getExperimentKey(experiment.id);
			setExperimentKey(experimentKey);
			const templates = await getTemplates();

			let projects = await getProjects();
			projects = projects.filter((project) => project.active);
			const projectExperiments = await axios.get(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/search/experiments`);
			let typesList = [];

			for (let project of projects) {
				const filteredExperiments = projectExperiments.data.filter((experiment) => experiment.projectId === project.id);
				project.experiments = filteredExperiments;

				if (!typesList.includes(project.type)) {
					typesList.push(project.type);
				}
			}

			const limsExperimentPermissions = await getExperimentsPermissions(projectId);
			const experimentTypes = await getDistinctExperimentTypes();
			
			const canEdit = experimentTypes.filter(e => 
				!limsExperimentPermissions
					.filter(p => p.experimentId == experiment.id && !p.edit)
					.length > 0
				&& (!e.useACL || e.RoleExperimentType.edit)
				&& e.id == experiment.experimentTypeId).length > 0;
			setProjects(projects);
			setIsPublished(experiment.isPublished);
			setPublisher(experiment.publisher);
			setIsReviewed(experiment.isReviewed);
			setReviewer(experiment.reviewer);
			setTemplates(templates);
			setCanEdit(canEdit);
			setLoading(false);
		};

		fetchData();
	}, [experimentKey, projectId, experimentId]);

	useEffect(() => {
		setTzOption(getTimezone(tzOption));
	}, [tzOption]);

	if (!project) {
	}

	if (!experiment) {
		return <Navigate to={"/projects/" + projectId + "/experiments"} />;
	}

	const onReviewClick = async () => {
		const userName = user.FirstName + " " + user.LastName;
		const dateTime = new Date();

		if (publisher === userName) {
			throw Error("A different user is required for reviewing.");
		}

		if (window.confirm("Are you sure you want to review this notebook?")) {
			setReviewer(userName);
			setIsReviewed(true);
			reviewNotebook(true, userName, dateTime, projectId, experimentId);
		}
	};

	const onPublishClick = () => {
		const userName = user.FirstName + " " + user.LastName;
		const dateTime = new Date();

		if (window.confirm("Are you sure you want to publish this notebook?")) {
			setPublisher(userName);
			setIsPublished(true);
			publishNotebook(true, userName, dateTime, projectId, experimentId);
		}
	};

	const onExportSubmit = async (e) => {
		e.preventDefault();

		try {
			setExportModalShow(false);
			setLoadingModalShow(true);
			await exportDocument(project.id, experiment.id);
			await createLog("Export Workbook to LIMS", `${user.FirstName} ${user.LastName} exported workbook ${experiment.title} to the LIMS`);
			await setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	const onModalShow = async (e) => {
		e.preventDefault();

		try {
			await forceSaveDocument(experimentKey, user.UserID);
			setExportModalShow(true);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		}
	};

	const publisherSignature = (
		<Fragment>
			Published by <strong>{publisher}</strong> on <strong>{formatTimezone(experiment.publishedAt ? experiment.publishedAt : new Date(), tzOption)}</strong>
		</Fragment>
	);

	const reviewerSignature = (
		<Fragment>
			Reviewed by <strong>{reviewer}</strong> on <strong>{formatTimezone(experiment.reviewedAt ? experiment.reviewedAt : new Date(), tzOption)}</strong>
		</Fragment>
	);

	const spinner = (
		<div className="d-flex justify-content-center">
			<div className="spinner-border m-5" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);

	return (
		<Fragment>
			{loading && spinner}
			{!loading && (
				<div className="container-fluid white-background">
					<LoadingModal modalShow={loadingModalShow} />
					<div className="row">
						<div className="col-lg-2 hidden-md">
							<div className="side-bar">
								<SideBar projectList={[].concat.apply([], projects)} />
							</div>
						</div>
						<div className="col-12 col-lg-10 workspace">
							<div className="container-fluid">
								<nav className="navbar navbar-expand-xl navbar-light">
									<h3>{experiment.title}</h3>
									<button
										className="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation">
										<span className="navbar-toggler-icon"></span>
									</button>
									<div className="collapse navbar-collapse" id="navbarSupportedContent">
										<ul className="navbar-nav mr-auto ml-auto">
											<li className="nav-item mr-3">
												{experiment.documentType !== "pdf" && (
													<LoadTemplateModal
														handleEditor={setExperimentKey}
														templates={templates}
														experiment={experiment}
														projectId={projectId}
														handleReload={() => setLoading(true)}
														disabled={isPublished}
													/>
												)}
											</li>
											{project && project.refInfoGroupId && (
												<li className="nav-item">
													<ModalButton
														button={
															<>
																<i className={`fas fa-file-arrow-up`} />
																<span> Export to LIMS</span>
															</>
														}
														title={"Export to LIMS Associated Files"}
														modalShow={exportModalShow}
														setModalShow={(bool) => setExportModalShow(bool)}
														onButtonClick={onModalShow}
														body={
															<>
																<Form onSubmit={(e) => onExportSubmit(e)}>
																	<Container>
																		<Button variant="primary" type="submit">
																			{"Export"}
																		</Button>
																	</Container>
																</Form>
															</>
														}
													/>
												</li>
											)}
										</ul>
										<ul className="navbar-nav">
											<li className="nav-item mr-3">
												<Link to="/projects" className="link">
													<i className="fas fa-project-diagram" />
													<span> Projects</span>
												</Link>
											</li>
											<li className="nav-item mr-3">
												<Link to="/templates" className="link">
													<i className="fas fa-copy" />
													<span> Templates</span>
												</Link>
											</li>
											<li className="nav-item mr-3">
												<Link to="/search" className="link">
													<i className="fas fa-search" />
													<span> Search</span>
												</Link>
											</li>
											<li className="nav-item">
												<Link to="/logs" className="link">
													<i className="fas fa-list" />
													<span> Activity Log</span>
												</Link>
											</li>
										</ul>
									</div>
								</nav>
								<div className="row">
									<div className="col-12 p-0">
										<DocEditor
											config={{
												document: {
													fileType: experiment.documentType,
													title: experiment.title,
													key: experimentKey,
													url: url + "/files/projects/" + project.id + "/" + experiment.id + "." + experiment.documentType,
													permissions: {
														edit: !isPublished && canEdit,
														review: !isReviewed && canEdit,
													},
												},
												documentType: experiment.documentType === "docx" ? "word" : "cell",
												key: experimentKey,
												editorConfig: {
													anonymous: {
														request: false,
													},
													user: {
														name: `${user.FirstName} ${user.LastName}`,
														id: user.UserID.toString(),
													},
													callbackUrl:
														url +
														"/api/onlyoffice/track?fileName=" +
														experiment.id +
														"." +
														experiment.documentType +
														"&description=" +
														experiment.description +
														"&title=" +
														experiment.title +
														"&projectTitle=" +
														project.id +
														"&urlPath=" +
														window.location.href +
														"&userName=" +
														`${user.FirstName} ${user.LastName}` +
														"&token=" +
														localStorage.token +
														"&flag=" +
														0 +
														"&userId=" +
														user.UserID,
												},
											}}
										/>
									</div>
									{experiment.documentType !== "pdf" && (
										<div className="container-fluid">
											<div className="row signature-button-wrapper">
												<div className="col-3 col-lg-1 signature-button">
													<button
														className="btn btn-primary mt-1"
														onClick={() => onReviewClick()}
														disabled={!isPublished || isReviewed || publisher === user.FirstName + " " + user.LastName}>
														Review
													</button>
												</div>
												<div className="col-3 col-lg-1 signature-button">
													<button className="btn btn-primary mt-1" onClick={() => onPublishClick()} disabled={isPublished}>
														Publish
													</button>
												</div>
												<div className="col-3 col-lg-5 signature">{isPublished && <Signature signature={publisherSignature} />}</div>
												<div className="col-3 col-lg-5 signature">{isReviewed && <Signature signature={reviewerSignature} />}</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

Notebook.propTypes = {
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
	tzOption: state.timezone.tzOption,
	limsModule: state.config.limsModule,
});

export default connect(mapStateToProps, { setAlert })(Notebook);
