import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DropdownLink from "./DropdownLink";
import { logout } from "../../actions/auth";
import { setTimezone } from "../../actions/timezone";
import { setCurrentUserLocationAction, loadUserLocations } from "../../actions/location";
import { hasTimezone } from "../../utils/timezone";
import AppLinks from "./AppLinks";
import DropdownSelector from "./DropdownSelector";

const Navbar = ({
	auth: { isAuthenticated, loading },
	FirstName,
	LastName,
	roles,
	clientName,
	logout,
	setTimezone,
	setCurrentUserLocationAction,
	loadUserLocations,
	useRoles,
	UserID,
	useOpenSocial,
	location,
}) => {
	const [dropdown, setDropdown] = useState(false);
	const [hasTz, setTz] = useState(false);
	const [roleIds, setRoleIds] = useState([]);
	const [locationsArray, setLocationsArray] = useState([]);

	const currentLocation = location.currentLocation;

	let ref = useRef();
	let ref2 = useRef();

	useEffect(() => {
		const asyncCall = async () => {
			if (isAuthenticated) {
				setTz(await hasTimezone());
				await loadUserLocations();
				setLocationsArray(
					location.locations
						? location.locations.map((location) => ({
								title: location.name,
								value: location.id,
						  }))
						: []
				);

				if (roles) {
					setRoleIds(roles.map((role) => role.id));
				}
			}
		};

		asyncCall();
	}, [roles, location.loading, isAuthenticated]);

	useEffect(() => {
		const handler = (event) => {
			if (dropdown && ref.current && !ref.current.contains(event.target)) {
				setDropdown(false);
			}
		};

		document.addEventListener("mousedown", handler);
		document.addEventListener("touchstart", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
			document.removeEventListener("touchstart", handler);
		};
	}, [dropdown]);

	const handleClick = (e) => {
		e.preventDefault();
		setTimezone(e.target.value);
		setDropdown(false);
	};

	const handleLocationClick = (e) => {
		e.preventDefault();
		setCurrentUserLocationAction(e.target.value);
		setDropdown(false);
	};

	const authLinks = (
		<div className="collapse navbar-collapse justify-content-end" id="navbarContent">
			<ul className="nav">
				<li className="nav-item">
					<AppLinks></AppLinks>
				</li>
				{hasTz && (
					<li className="nav-item" ref={ref}>
						<button className="navbar-link nav-link nav-text" onClick={() => setDropdown(!dropdown)}>
							<i className="fas fa-globe" /> Timezone
						</button>
						<DropdownSelector
							subSelections={[
								{
									title: "LIMS",
									value: 1,
								},
								{
									title: "Local",
									value: 0,
								},
							]}
							dropdown={dropdown}
							handleClick={handleClick}
						/>
					</li>
				)}
				<li className="nav-item" ref={ref2}>
					{currentLocation && <DropdownLink linkTitle={`Location: ${currentLocation.name}`} selectionsArray={locationsArray} handleClick={handleLocationClick} sendRef={ref2} />}
				</li>

				{(roleIds.includes(-2) || UserID === -1) && (
					<li className="nav-item">
						<Link to="/admin" className="nav-link nav-text">
							<i className="fas fa-gear" /> Settings
						</Link>
					</li>
				)}
				<li className="nav-item nav-text">
					<i className="fas fa-user" /> {FirstName + " " + LastName}
				</li>
				<li className="nav-item">
					<Link to="/" onClick={() => logout(useOpenSocial)} className="nav-link nav-text">
						<i className="fas fa-sign-out-alt" /> Logout
					</Link>
				</li>
			</ul>
		</div>
	);

	const guestLinks = (
		<div className="ml-auto">
			<ul className="nav">
				<li className="nav-item">
					<Link to="/login" className="nav-link nav-text">
						Login
					</Link>
				</li>
			</ul>
		</div>
	);

	return (
		<nav className="navbar company-colors navbar-expand-xl">
			<Link to={!loading && isAuthenticated ? "/projects" : "/"} className="navbar-brand nav-text">
				<i className="fas fa-flask" /> {clientName ? clientName : "Client"}
			</Link>
			<button
				className="navbar-toggler navbar-light"
				type="button"
				data-toggle="collapse"
				data-target="#navbarContent"
				aria-controls="navbarContent"
				aria-expanded="false"
				aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			{!loading && <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>}
		</nav>
	);
};

Navbar.propTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	FirstName: PropTypes.string.isRequired,
	LastName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	UserID: state.auth.user.UserID,
	FirstName: state.auth.user.FirstName,
	LastName: state.auth.user.LastName,
	roles: state.auth.user.roles,
	clientName: state.config.clientName,
	useRoles: state.config.useRoles,
	useOpenSocial: state.config.useOpenSocial,
	location: state.location,
});

export default connect(mapStateToProps, { logout, setTimezone, setCurrentUserLocationAction, loadUserLocations })(Navbar);
