import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const PopupModal = ({ title, body, show }) => {
  const [modalShow, setModalShow] = useState(show);
  
  return (
    <Modal show={modalShow} onHide={() => setModalShow(false)} size="sm" aria-labelledby="popup-modal" centered>
    <Modal.Header closeButton>
        <Modal.Title id="popup-modal">
            { title }
        </Modal.Title>
    </Modal.Header>
        <Modal.Body>
            { body }
        </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
