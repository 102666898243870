import { LOCATION_LOADED, LOCATION_ERROR, LOCATION_CREATED, LOCATION_UPDATED, LOCATION_SET, LOCATION_SET_CURRENT } from "../actions/types";

const initialState = {
	loading: true,
	locations: [],
	currentLocation: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOCATION_LOADED:
			return {
				...state,
				loading: false,
				locations: payload.locations,
				currentLocation: payload.currentLocation,
			};
		case LOCATION_SET:
			return {
				...state,
				locations: payload,
			};
		case LOCATION_SET_CURRENT:
			return {
				...state,
				currentLocation: payload,
			};
		case LOCATION_UPDATED:
		case LOCATION_CREATED:
			return {
				...state,
				loading: true,
			};
		case LOCATION_ERROR:
			return {
				...state,
			};
		default:
			return state;
	}
}
