import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import ModalButton from "../../../layout/ModalButton";
import LoadingModal from "../../../layout/LoadingModal";
import Spinner from "../../../layout/Spinner";
import ClickableCard from "../../../layout/ClickableCard";
import { setAlert } from "../../../../actions/alert";
import { getAllDataConnections, createDataConnection, updateDataConnection, deleteDataConnection, getAllAuthTypes } from "../../../../utils/apiCall";

const DataConnections = ({ setAlert }) => {
	const [formData, setFormData] = useState({
		name: "",
		baseUrl: "",
		username: "",
		password: "",
		token: "",
		header: "",
		authTypeId: "1",
	});
	const [dataConnections, setDataConnections] = useState([]);
	const [authTypes, setAuthTypes] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setDataConnections(await getAllDataConnections());
		setAuthTypes(await getAllAuthTypes());
		setLoading(false);
	};

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			await createDataConnection(formData.name, formData.baseUrl, formData.username, formData.password, formData.token, formData.header, formData.authTypeId);
			setDataConnections(await getAllDataConnections());

			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<>
			<div className="pt-2 pb-2 border-top">
				<LoadingModal modalShow={loadingModalShow} />
				<Row>
					<Col className="col-2">
						<ModalButton
							button={
								<>
									<i className={`fas fa-plus`} />
									<span> New Data Connection</span>
								</>
							}
							title={"New Data Connection"}
							modalShow={modalShow}
							setModalShow={(bool) => setModalShow(bool)}
							onButtonClick={() => setModalShow(true)}
							body={<DataConnectionsForm onSubmit={onSubmit} onChange={onChange} formData={formData} authTypes={authTypes} submitLabel={"Create"} />}
						/>
					</Col>
				</Row>
			</div>
			<ul className="no-bullets p-0">
				{loading && <Spinner />}
				{!loading &&
					dataConnections.map((dataConnection) => {
						return (
							<NestedList setAlert={setAlert} setLoadingModalShow={setLoadingModalShow} dataConnection={dataConnection} setDataConnections={setDataConnections} authTypes={authTypes} />
						);
					})}
			</ul>
		</>
	);
};

const NestedList = ({ setAlert, setLoadingModalShow, dataConnection, setDataConnections, authTypes }) => {
	const [editModalShow, setEditModalShow] = useState(false);
	const [deleteModalShow, setDeleteModalShow] = useState(false);

	const onEditClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setEditModalShow(bool);
	};

	const onDeleteClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setDeleteModalShow(bool);
	};

	const onDeleteSubmit = async (e, id) => {
		e.preventDefault();
		setDeleteModalShow(false);
		setLoadingModalShow(true);

		try {
			await deleteDataConnection(dataConnection.id);
			setDataConnections(await getAllDataConnections());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	const authTypeName = authTypes.filter((authType) => authType.id === dataConnection.authTypeId)[0].name;

	return (
		<li key={dataConnection.id}>
			<ClickableCard
				onClick={(e) => onEditClick(e)}
				body={
					<Row>
						<Col className="col-8">
							<span>
								<strong>
									{dataConnection.id} - {dataConnection.name}
								</strong>
								{": "}
								{authTypeName}
							</span>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Edit </span>
										<i className={`fas fa-edit`} />
									</>
								}
								title={"Edit"}
								body={
									<EditForm
										id={dataConnection.id}
										name={dataConnection.name}
										baseUrl={dataConnection.baseUrl}
										username={dataConnection.username}
										password={dataConnection.password}
										token={dataConnection.token}
										header={dataConnection.header}
										authTypeId={dataConnection.authTypeId}
										authTypes={authTypes}
										setEditModalShow={(bool) => setEditModalShow(bool)}
										setLoadingModalShow={(bool) => setLoadingModalShow(bool)}
										setDataConnections={setDataConnections}
										setAlert={setAlert}
									/>
								}
								modalShow={editModalShow}
								setModalShow={(bool) => setEditModalShow(bool)}
								onButtonClick={(e) => onEditClick(e, true)}
							/>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Delete </span>
										<i className={`fas fa-trash`} />
									</>
								}
								variant={"danger"}
								title={`Delete Data Connection ${dataConnection.name}?`}
								body={
									<Row>
										<Col>
											<Button className="col-6" variant="danger" onClick={(e) => onDeleteSubmit(e)}>
												{"Ok"}
											</Button>
										</Col>
										<Col>
											<Button className="col-6" variant="secondary" onClick={() => setDeleteModalShow(false)}>
												{"Cancel"}
											</Button>
										</Col>
									</Row>
								}
								modalShow={deleteModalShow}
								setModalShow={(bool) => setDeleteModalShow(bool)}
								onButtonClick={(e) => onDeleteClick(e, true)}
							/>
						</Col>
					</Row>
				}
			/>
		</li>
	);
};

const EditForm = ({ id, name, baseUrl, username, password, token, header, authTypeId, authTypes, setEditModalShow, setLoadingModalShow, setDataConnections, setAlert }) => {
	const [formData, setFormData] = useState({
		id,
		name,
		baseUrl,
		username,
		password,
		token,
		header,
		authTypeId,
	});

	const onEditChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onEditSubmit = async (e) => {
		e.preventDefault();
		setEditModalShow(false);
		setLoadingModalShow(true);

		try {
			const { name, baseUrl, username, password, token, header, authTypeId } = formData;
			await updateDataConnection(id, name, baseUrl, username, password, token, header, authTypeId);
			setDataConnections(await getAllDataConnections());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return <DataConnectionsForm onSubmit={onEditSubmit} onChange={onEditChange} formData={formData} authTypes={authTypes} submitLabel={"Edit"} />;
};

const DataConnectionsForm = ({ onSubmit, onChange, formData, authTypes, submitLabel }) => {
	const [fieldType, setFieldType] = useState("password");

	const showPasswordIcon = "fas fa-eye";
	const hidePasswordIcon = "fas fa-eye-slash";

	const showHide = (e) => {
		e.preventDefault();
		setFieldType(fieldType === "password" ? "text" : "password");
	};

	return (
		<Form onSubmit={(e) => onSubmit(e)}>
			<Form.Group controlId="formConnectionName">
				<Form.Label>Connection Name</Form.Label>
				<Form.Control required type="text" name="name" placeholder="Enter Connection Name" value={formData.name} onChange={(e) => onChange(e)} />
			</Form.Group>
			<Form.Group controlId="formBaseUrl">
				<Form.Label>Base URL</Form.Label>
				<Form.Control required type="text" name="baseUrl" placeholder="Enter Base URL" value={formData.baseUrl} onChange={(e) => onChange(e)} />
			</Form.Group>
			<Form.Group controlId="formAuthTypeId">
				<Form.Label>Auth Type</Form.Label>
				<Form.Control as="select" name="authTypeId" value={formData.authTypeId} onChange={(e) => onChange(e)}>
					{authTypes ? authTypes.map((authType) => <option value={authType.id}>{authType.name}</option>) : ""}
				</Form.Control>
			</Form.Group>
			{formData.authTypeId && formData.authTypeId.toString() === "1" && (
				<>
					<Form.Group controlId="formToken">
						<Form.Label>Token</Form.Label>
						<Row>
							<Col className="col-10">
								<Form.Control required type={fieldType} name="token" placeholder="Enter Token" value={formData.token} onChange={(e) => onChange(e)} />
							</Col>
							<Col className="col-2">
								<span className={fieldType === "password" ? showPasswordIcon : hidePasswordIcon} onClick={showHide}></span>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group controlId="formHeader">
						<Form.Label>Header</Form.Label>
						<Form.Control required type="text" name="header" placeholder="Enter Header" value={formData.header} onChange={(e) => onChange(e)} />
					</Form.Group>
				</>
			)}
			{formData.authTypeId && formData.authTypeId.toString() === "2" && (
				<Form.Group controlId="formBearerToken">
					<Form.Label>Token</Form.Label>
					<Row>
						<Col className="col-10">
							<Form.Control required type={fieldType} name="token" placeholder="Enter Token" value={formData.token} onChange={(e) => onChange(e)} />
						</Col>
						<Col className="col-2">
							<span className={fieldType === "password" ? showPasswordIcon : hidePasswordIcon} onClick={showHide}></span>
						</Col>
					</Row>
				</Form.Group>
			)}
			{formData.authTypeId && formData.authTypeId.toString() === "3" && (
				<>
					<Form.Group controlId="formUsername">
						<Form.Label>Username</Form.Label>
						<Form.Control required type="text" name="username" placeholder="Enter Username" value={formData.username} onChange={(e) => onChange(e)} />
					</Form.Group>
					<Form.Group controlId="formHeader">
						<Form.Label>Password</Form.Label>
						<Row>
							<Col className="col-10">
								<Form.Control required type={fieldType} name="password" placeholder="Enter password" value={formData.password} onChange={(e) => onChange(e)} />
							</Col>
							<Col className="col-2">
								<span className={fieldType === "password" ? showPasswordIcon : hidePasswordIcon} onClick={showHide}></span>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group controlId="formHeader">
						<Form.Label>Header</Form.Label>
						<Form.Control required type="text" name="header" placeholder="Enter Header" value={formData.header} onChange={(e) => onChange(e)} />
					</Form.Group>
				</>
			)}
			{formData.authTypeId && formData.authTypeId.toString() === "4" && (
				<>
					<Form.Group controlId="formAppKey">
						<Form.Label>App Key</Form.Label>
						<Row>
							<Col className="col-10">
								<Form.Control required type={fieldType} name="token" placeholder="Enter App Key" value={formData.token} onChange={(e) => onChange(e)} />
							</Col>
							<Col className="col-2">
								<span className={fieldType === "password" ? showPasswordIcon : hidePasswordIcon} onClick={showHide}></span>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group controlId="formUsername">
						<Form.Label>Username</Form.Label>
						<Form.Control required type="text" name="username" placeholder="Enter Username" value={formData.username} onChange={(e) => onChange(e)} />
					</Form.Group>
					<Form.Group controlId="formHeader">
						<Form.Label>Header</Form.Label>
						<Form.Control required type="text" name="header" placeholder="Enter Header" value={formData.header} onChange={(e) => onChange(e)} />
					</Form.Group>
				</>
			)}
			<Container>
				<Button variant="primary" type="submit" className="mt-2">
					{submitLabel}
				</Button>
			</Container>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { setAlert })(DataConnections);
