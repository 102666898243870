import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import ModalButton from "../../../layout/ModalButton";
import LoadingModal from "../../../layout/LoadingModal";
import Spinner from "../../../layout/Spinner";
import ClickableCard from "../../../layout/ClickableCard";
import { setAlert } from "../../../../actions/alert";
import { createLocationAction } from "../../../../actions/location";
import { getAllLocations, deleteLocationById, updateLocationById, importLocations } from "../../../../utils/apiCall";

const Locations = ({ setAlert, createLocationAction, location, selectedLocationEvent }) => {
	const [formData, setFormData] = useState({
		name: "",
	});
	const [locations, setLocations] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, [location]);

	const fetchData = async () => {
		const locations = await getAllLocations();
		setLocations(locations);
		setLoading(false);
	};

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			await createLocationAction(formData.name);

			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	const importLocationsClick = async (e) => {
		e.preventDefault();

		try {
			setLoadingModalShow(true);
			await importLocations(selectedLocationEvent);
			setLocations(await await getAllLocations());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<>
			<div className="pt-2 pb-2 border-top">
				<LoadingModal modalShow={loadingModalShow} />
				<Row>
					<Col className="col-2">
						<ModalButton
							button={
								<>
									<i className={`fas fa-plus`} />
									<span> New Location</span>
								</>
							}
							title={"New Location"}
							modalShow={modalShow}
							setModalShow={(bool) => setModalShow(bool)}
							onButtonClick={() => setModalShow(true)}
							body={<LocationsForm onSubmit={onSubmit} onChange={onChange} formData={formData} submitLabel={"Create"} />}
						/>
					</Col>
					<Col className="col-3">
						<Button variant="primary" className="modal-button btn-sm" onClick={(e) => importLocationsClick(e)}>
							<i className={`fas fa-circle-down`} />
							<span> Import Locations</span>
						</Button>
					</Col>
				</Row>
			</div>
			<ul className="no-bullets p-0">
				{loading && <Spinner />}
				{!loading &&
					locations.map((location) => {
						return <NestedList setAlert={setAlert} setLoadingModalShow={setLoadingModalShow} location={location} setLocations={setLocations} />;
					})}
			</ul>
		</>
	);
};

const NestedList = ({ setAlert, setLoadingModalShow, location, setLocations }) => {
	const [editModalShow, setEditModalShow] = useState(false);
	const [deleteModalShow, setDeleteModalShow] = useState(false);

	const onEditClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setEditModalShow(bool);
	};

	const onDeleteClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setDeleteModalShow(bool);
	};

	const onDeleteSubmit = async (e, id) => {
		e.preventDefault();
		setDeleteModalShow(false);
		setLoadingModalShow(true);

		try {
			await deleteLocationById(location.id);
			setLocations(await getAllLocations());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			console.log(err);
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<li key={location.id}>
			<ClickableCard
				onClick={(e) => onEditClick(e)}
				body={
					<Row>
						<Col className="col-8">
							<span>
								<strong>
									{location.id} - {location.name}
								</strong>
							</span>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Edit </span>
										<i className={`fas fa-edit`} />
									</>
								}
								title={"Edit"}
								body={
									<EditForm
										id={location.id}
										name={location.name}
										setEditModalShow={(bool) => setEditModalShow(bool)}
										setLoadingModalShow={(bool) => setLoadingModalShow(bool)}
										setLocations={setLocations}
										setAlert={setAlert}
									/>
								}
								modalShow={editModalShow}
								setModalShow={(bool) => setEditModalShow(bool)}
								onButtonClick={(e) => onEditClick(e, true)}
							/>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Delete </span>
										<i className={`fas fa-trash`} />
									</>
								}
								variant={"danger"}
								title={`Delete Location ${location.name}?`}
								body={
									<Row>
										<Col>
											<Button className="col-6" variant="danger" onClick={(e) => onDeleteSubmit(e)}>
												{"Ok"}
											</Button>
										</Col>
										<Col>
											<Button className="col-6" variant="secondary" onClick={() => setDeleteModalShow(false)}>
												{"Cancel"}
											</Button>
										</Col>
									</Row>
								}
								modalShow={deleteModalShow}
								setModalShow={(bool) => setDeleteModalShow(bool)}
								onButtonClick={(e) => onDeleteClick(e, true)}
							/>
						</Col>
					</Row>
				}
			/>
		</li>
	);
};

const EditForm = ({ id, name, setEditModalShow, setLoadingModalShow, setLocations, setAlert }) => {
	const [formData, setFormData] = useState({
		id,
		name,
	});

	const onEditChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onEditSubmit = async (e) => {
		e.preventDefault();
		setEditModalShow(false);
		setLoadingModalShow(true);

		try {
			const { name } = formData;
			await updateLocationById(id, name);
			setLocations(await getAllLocations());
			setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return <LocationsForm onSubmit={onEditSubmit} onChange={onEditChange} formData={formData} submitLabel={"Edit"} />;
};

const LocationsForm = ({ onSubmit, onChange, formData, submitLabel }) => {
	return (
		<Form onSubmit={(e) => onSubmit(e)}>
			<Form.Group controlId="formLocationName">
				<Form.Label>Location Name</Form.Label>
				<Form.Control required type="text" name="name" placeholder="Enter Location Name" value={formData.name} onChange={(e) => onChange(e)} />
			</Form.Group>
			<Container>
				<Button variant="primary" type="submit" className="mt-2">
					{submitLabel}
				</Button>
			</Container>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
	location: state.location,
	selectedLocationEvent: state.config.selectedLocationEvent,
});

export default connect(mapStateToProps, { setAlert, createLocationAction })(Locations);
