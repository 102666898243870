import axios from "../constants/axiosRequest";

const logger = async (level, message) => {
	try {
		const body = { level: level, message: message };
		await axios.post(`${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/clientLogger`, body);
	} catch (err) {
		console.log(err);
	}
};

export default logger;
