import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Table } from "react-bootstrap";
import { connect } from "react-redux";
import ModalButton from "../../../layout/ModalButton";
import ToggleButton from "../../../layout/ToggleButton";
import ClickableCard from "../../../layout/ClickableCard";
import Spinner from "../../../layout/Spinner";
import LoadingModal from "../../../layout/LoadingModal";
import { setAlert } from "../../../../actions/alert";
import { createExperimentType, updateExperimentType, getExperimentTypes, getRoles } from "../../../../utils/apiCall";

const ExperimentTypes = ({ setAlert, loading }) => {
	const [formCreateData, setFormCreateData] = useState({
		active: true,
	});
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [experimentTypes, setExperimentTypes] = useState([]);

	useEffect(() => {
		const getData = async () => {
			setExperimentTypes(await getExperimentTypes());
		};

		getData();
	}, []);

	const onChange = async (e) => {
		setFormCreateData({
			...formCreateData,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			const { experimentTypeName, useACL, active } = formCreateData;

			const newExperimentType = await createExperimentType(experimentTypeName, active);

			if (newExperimentType) {
				setExperimentTypes([...experimentTypes, newExperimentType]);
				await setAlert("Successfully Saved", "success", 1500);
			}
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<>
			<LoadingModal modalShow={loadingModalShow} />
			<div className="pt-2 pb-2 border-top">
				<ModalButton
					button={
						<>
							<i className={`fas fa-plus`} />
							<span> New Workbook Type</span>
						</>
					}
					title={"New Workbook Type"}
					modalShow={modalShow}
					setModalShow={(bool) => setModalShow(bool)}
					onButtonClick={() => setModalShow(true)}
					body={
						<>
							<Form onSubmit={(e) => onSubmit(e)}>
								<Form.Group controlId="formExperimentTypeName" className="ml-3 mt-3">
									<Form.Label>Type Name</Form.Label>
									<Form.Control required type="text" name="experimentTypeName" placeholder="Enter Workbook Type Name" value={formCreateData.name} onChange={(e) => onChange(e)} />
								</Form.Group>
								<Container className="mt-3">
									<Button variant="primary" type="submit">
										{"Create"}
									</Button>
								</Container>
							</Form>
						</>
					}
				/>
			</div>
			<ul className="no-bullets p-0">
				{loading && <Spinner />}
				{!loading &&
					experimentTypes &&
					experimentTypes.map((experimentType) => {
						return <NestedList setAlert={setAlert} experimentType={experimentType} setLoadingModalShow={(bool) => setLoadingModalShow(bool)} setExperimentTypes={setExperimentTypes} />;
					})}
			</ul>
		</>
	);
};

const NestedList = ({ setAlert, experimentType, setLoadingModalShow, setExperimentTypes }) => {
	const [displayContent, setDisplayContent] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);

	const onClick = (e) => {
		e.preventDefault();
		setDisplayContent(!displayContent);
	};

	const onEditClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setEditModalShow(bool);
	};

	return (
		<li key={experimentType.id}>
			<ClickableCard
				onClick={(e) => onClick(e)}
				body={
					<Row>
						<Col className="col-10">
							<span>{experimentType.typeName}</span>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Edit </span>
										<i className={`fas fa-edit`} />
									</>
								}
								title={"Edit"}
								body={
									<EditForm
										id={experimentType.id}
										name={experimentType.typeName}
										useACL={experimentType.useACL}
										selectedRoles={experimentType.roles}
										active={experimentType.active}
										setEditModalShow={(bool) => setEditModalShow(bool)}
										setLoadingModalShow={(bool) => setLoadingModalShow(bool)}
										setAlert={setAlert}
										setExperimentTypes={setExperimentTypes}
									/>
								}
								modalShow={editModalShow}
								setModalShow={(bool) => setEditModalShow(bool)}
								onButtonClick={(e) => onEditClick(e, true)}
							/>
						</Col>
					</Row>
				}
			/>
		</li>
	);
};

const EditForm = ({ id, name, useACL, selectedRoles, active, setEditModalShow, setLoadingModalShow, setAlert, setExperimentTypes }) => {
	const [formData, setFormData] = useState({
		id,
		name,
		useACL,
		roles: selectedRoles,
		active,
	});

	const [rolesData, setRolesData] = useState(
		selectedRoles
			? selectedRoles.map((role) => ({ id: role.id, selectedRole: role.id, create: role.RoleExperimentType.create, edit: role.RoleExperimentType.edit }))
			: [{ id: Date.now(), selectedRole: "", create: false, edit: false }]
	);

	const [roles, setRoles] = useState([]);

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const fetchedRoles = await getRoles();
				setRoles(fetchedRoles);
			} catch (error) {
				console.error("Error fetching roles:", error);
			}
		};

		fetchRoles();
	}, []);

	const handleRoleChange = (e, index) => {
		const updatedRolesData = [...rolesData];
		updatedRolesData[index] = { ...updatedRolesData[index], selectedRole: e.target.value };
		setRolesData(updatedRolesData);
	};

	const handleAddRole = () => {
		setRolesData([...rolesData, { id: Date.now(), selectedRole: "", create: false, edit: false }]);
	};

	const handleRemoveRole = (index) => {
		const updatedRolesData = [...rolesData];
		updatedRolesData.splice(index, 1);
		setRolesData(updatedRolesData);
	};

	const onEditChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleCheckboxClick = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.checked.toString() });
	};

	const handleCreateChange = (e, index) => {
		const updatedRolesData = [...rolesData];
		updatedRolesData[index].create = e.target.checked;
		setRolesData(updatedRolesData);
	};

	const handleEditChange = (e, index) => {
		const updatedRolesData = [...rolesData];
		updatedRolesData[index].edit = e.target.checked;
		setRolesData(updatedRolesData);
	};

	const onEditSubmit = async (e) => {
		e.preventDefault();
		setEditModalShow(false);
		setLoadingModalShow(true);

		try {
			const { id, name, useACL, active } = formData;

			const updatedExperimentType = await updateExperimentType(
				id,
				name,
				useACL,
				rolesData.map((role) => ({ id: role.selectedRole, create: role.create, edit: role.edit })),
				active
			);

			if (updatedExperimentType) {
				setExperimentTypes(await getExperimentTypes());
				setAlert("Successfully Saved", "success", 1500);
			}
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<Form onSubmit={(e) => onEditSubmit(e)}>
			<Form.Group controlId="formEditExperimentTypeName" className="ml-3 mt-3">
				<Form.Label>Name</Form.Label>
				<Form.Control required type="text" name="name" placeholder="Enter Workbook Type Name" value={formData.name} onChange={(e) => onEditChange(e)} />
			</Form.Group>
			<Form.Group controlId="formEditExperimentTypeUseACL" className="mt-3">
				<Form.Check label="Use Role Based ACL" type="checkbox" name="useACL" checked={formData.useACL} onChange={(e) => handleCheckboxClick(e)} />
			</Form.Group>
			{formData.useACL && (
				<Form.Group controlId="formEditExperimentTypeRoles" className="mt-3">
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Role</th>
								<th>Create</th>
								<th>Edit</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{rolesData.map((roleData, index) => (
								<tr key={roleData.id}>
									<td>
										<Form.Control as="select" value={roleData.selectedRole} onChange={(e) => handleRoleChange(e, index)}>
											<option value="">Select Role</option>
											{roles.map((role) => (
												<option key={role.id} value={role.id}>
													{role.name}
												</option>
											))}
										</Form.Control>
									</td>
									<td>
										<Form.Group controlId={`create-${index}`}>
											<Form.Check type="checkbox" checked={roleData.create} onChange={(e) => handleCreateChange(e, index)} inline />
										</Form.Group>
									</td>
									<td>
										<Form.Group controlId={`edit-${index}`}>
											<Form.Check type="checkbox" checked={roleData.edit} onChange={(e) => handleEditChange(e, index)} inline />
										</Form.Group>
									</td>
									<td>
										<Button variant="danger" size="sm" onClick={() => handleRemoveRole(index)}>
											Remove Role
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Form.Group>
			)}
			{formData.useACL && (
				<Button variant="primary" className="mt-3" size="sm" onClick={handleAddRole}>
					Add Role
				</Button>
			)}
			<Form.Group controlId="formEditExperimentTypeActive">
				<Form.Label>Active</Form.Label>
				<ToggleButton title="experimentTypeActive" name="active" onClick={(e) => handleCheckboxClick(e)} defaultChecked={formData.active} />
			</Form.Group>
			<Container className="mt-3">
				<Button variant="primary" type="submit">
					{"Save"}
				</Button>
			</Container>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	loading: state.experimentType.loading,
});

export default connect(mapStateToProps, {
	setAlert,
})(ExperimentTypes);
