import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import ModalLink from "../layout/ModalLink";
import LoadingModal from "../layout/LoadingModal";
import { setAlert } from "../../actions/alert";
import { passwordResetEmail } from "../../utils/apiCall";
import placeholder from "../../img/Lablynx-logo.png";

const Landing = ({ setAlert, logo }) => {
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [formData, setFormData] = useState({ email: "" });

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			const emailData = await passwordResetEmail(formData.email);

			if (emailData) {
				setAlert("Email Sent", "success", 1500);
			}
		} catch (err) {
			setAlert(err, "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<div className="container-fluid">
			<LoadingModal modalShow={loadingModalShow} />
			<div className="row justify-content-center logo">
				<div className="col- shadow bg-white rounded panel text-center">
					<span>
						<img
							src={
								logo
									? `data:image/jpeg;base64, ${logo}`
									: placeholder
							}
							alt="Client Logo"
							className="img-fluid"
						/>
					</span>
					<div className="container row justify-content-center m-0 mt-5">
						<Link to="/login" className="btn btn-primary">
							Login
						</Link>
					</div>
					<div className="container row justify-content-center m-0 mt-5">
						<button
							className="btn btn-primary"
							onClick={() =>
								(window.location = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/opensocial/login`)
							}
						>
							Login With OpenSocial
						</button>
					</div>
					<div className="container row justify-content-center m-0 mt-5">
						<ModalLink
							link={"Reset Password"}
							title={"Reset Password"}
							body={
								<Form onSubmit={(e) => onSubmit(e)}>
									<Form.Group controlId="formEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control
											required
											type="text"
											name="email"
											placeholder="Enter your email"
											value={formData.email}
											onChange={(e) => onChange(e)}
										/>
									</Form.Group>
									<Container>
										<Button variant="primary" type="submit">
											{"Submit"}
										</Button>
									</Container>
								</Form>
							}
							modalShow={modalShow}
							setModalShow={(bool) => setModalShow(bool)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	logo: state.config.logo,
});

export default connect(mapStateToProps, { setAlert })(Landing);
