import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import ModalButton from "../../../layout/ModalButton";
import ToggleButton from "../../../layout/ToggleButton";
import Spinner from "../../../layout/Spinner";
import LoadingModal from "../../../layout/LoadingModal";
import ClickableCard from "../../../layout/ClickableCard";
import AssignUnassignTable from "../../../layout/AssignUnassignTable";
import { setAlert } from "../../../../actions/alert";
import { getRoles, createRole, updateRole, setRoleProjectTypes } from "../../../../utils/apiCall";

const Roles = ({ setAlert, loading, projectTypes }) => {
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		active: true,
	});
	const [roles, setRoles] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setRoles(await getRoles());
		};

		fetchData();
	}, []);

	const onChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setModalShow(false);
		setLoadingModalShow(true);

		try {
			const role = await createRole(formData);

			if (role) {
				setRoles(await getRoles());
				setAlert("Successfully Saved", "success", 1500);
			}
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<>
			<LoadingModal modalShow={loadingModalShow} />
			<div className="pt-2 pb-2 border-top">
				<ModalButton
					button={
						<>
							<i className={`fas fa-plus`} />
							<span> New Role</span>
						</>
					}
					title={"New Role"}
					modalShow={modalShow}
					setModalShow={(bool) => setModalShow(bool)}
					onButtonClick={() => setModalShow(true)}
					body={
						<>
							<Form onSubmit={(e) => onSubmit(e)}>
								<Form.Group controlId="formRoleName">
									<Form.Label>Name</Form.Label>
									<Form.Control required type="text" name="name" placeholder="Enter Role Name" value={formData.name} onChange={(e) => onChange(e)} />
								</Form.Group>
								<Form.Group controlId="formRoleDescription">
									<Form.Label>Description</Form.Label>
									<Form.Control required type="text" name="description" placeholder="Enter Role Description" value={formData.description} onChange={(e) => onChange(e)} />
								</Form.Group>
								<Container>
									<Button variant="primary" type="submit">
										{"Create"}
									</Button>
								</Container>
							</Form>
						</>
					}
				/>
			</div>
			<ul className="no-bullets p-0">
				{loading && <Spinner />}
				{!loading &&
					roles.map((role) => {
						return <NestedList setRoles={setRoles} setAlert={setAlert} projectTypes={projectTypes} role={role} setLoadingModalShow={(bool) => setLoadingModalShow(bool)} />;
					})}
			</ul>
		</>
	);
};

const NestedList = ({ setRoles, setAlert, projectTypes, role, setLoadingModalShow }) => {
	const [displayContent, setDisplayContent] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);

	const onClick = (e) => {
		e.preventDefault();
		setDisplayContent(!displayContent);
	};

	const onEditClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setEditModalShow(bool);
	};

	return (
		<li key={role.id}>
			<ClickableCard
				onClick={(e) => onClick(e)}
				body={
					<Row>
						<Col className="col-10">
							<span>{role.name}</span>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Edit </span>
										<i className={`fas fa-edit`} />
									</>
								}
								title={"Edit"}
								body={
									<EditForm
										id={role.id}
										name={role.name}
										description={role.description}
										active={role.active}
										setEditModalShow={(bool) => setEditModalShow(bool)}
										setLoadingModalShow={(bool) => setLoadingModalShow(bool)}
										setRoles={setRoles}
										setAlert={setAlert}
									/>
								}
								modalShow={editModalShow}
								setModalShow={(bool) => setEditModalShow(bool)}
								onButtonClick={(e) => onEditClick(e, true)}
							/>
						</Col>
					</Row>
				}
			/>
			{displayContent && <ElnTable setRoles={setRoles} setAlert={setAlert} role={role} list1={projectTypes} list2={role.projectTypes} />}
		</li>
	);
};

const EditForm = ({ id, name, description, active, setEditModalShow, setLoadingModalShow, setRoles, setAlert }) => {
	const [formData, setFormData] = useState({
		id,
		name,
		description,
		active,
	});

	const onEditChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleCheckboxClick = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.checked.toString() });
	};

	const onEditSubmit = async (e) => {
		e.preventDefault();
		setEditModalShow(false);
		setLoadingModalShow(true);

		try {
			const { id, name, description, active } = formData;
			const updatedRole = await updateRole(id, name, description, active);

			if (updatedRole) {
				setRoles(await getRoles());
				setAlert("Successfully Saved", "success", 1500);
			}
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<Form onSubmit={(e) => onEditSubmit(e)}>
			<Form.Group controlId="formEditRoleName">
				<Form.Label>Name</Form.Label>
				<Form.Control required type="text" name="name" placeholder="Enter Role Name" value={formData.name} onChange={(e) => onEditChange(e)} />
			</Form.Group>
			<Form.Group controlId="formEditRoleDescription">
				<Form.Label>Description</Form.Label>
				<Form.Control required type="text" name="description" placeholder="Enter Role Description" value={formData.description} onChange={(e) => onEditChange(e)} />
			</Form.Group>
			<Form.Group controlId="formEditRoleActive">
				<Form.Label>Active</Form.Label>
				<ToggleButton title="roleActive" name="active" onClick={(e) => handleCheckboxClick(e)} defaultChecked={formData.active} />
			</Form.Group>
			<Container>
				<Button variant="primary" type="submit">
					{"Save"}
				</Button>
			</Container>
		</Form>
	);
};

const ElnTable = ({ setRoles, setAlert, role, list1, list2 }) => {
	const [assigned, setAssigned] = useState([{}]);
	const [unassigned, setUnassigned] = useState([{}]);
	const [loadingModalShow, setLoadingModalShow] = useState(false);

	useEffect(() => {
		let newList2 = list2.map((el) => el);
		const list2Data = list2.map((el) => el.typeName);
		let newList1 = list1.filter((el) => !list2Data.includes(el.typeName) && el.active);

		for (let i = 0; i < newList1.length; i++) {
			newList1[i].checked = false;
		}

		for (let i = 0; i < newList2.length; i++) {
			newList2[i].checked = false;
		}

		setAssigned(newList2);
		setUnassigned(newList1);
	}, [list1, list2]);

	const handleSave = async (e) => {
		e.preventDefault();

		try {
			setLoadingModalShow(true);

			const roleId = role.id;
			const projectTypeIdArr = assigned.map((el) => el.projectTypeId);

			await setRoleProjectTypes({ roleId, projectTypeIdArr });
			setRoles(await getRoles());
			await setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<>
			<LoadingModal modalShow={loadingModalShow} />
			<AssignUnassignTable assigned={assigned} setAssigned={setAssigned} unassigned={unassigned} setUnassigned={setUnassigned} handleSave={handleSave} dataAttribute={"typeName"} />
		</>
	);
};

const mapStateToProps = (state) => ({
	projectTypes: state.projectType.projectTypes,
	loading: state.projectType.loading,
	user: state.auth.user,
});

export default connect(mapStateToProps, { setAlert })(Roles);
