import React from "react";

const ToggleButton = ({ title, name, onClick, defaultChecked }) => {
	return (
		<>
			<input type="checkbox" id={name} name={name} className="toggle-input" onClick={(e) => onClick(e)} defaultChecked={defaultChecked} />
			<label htmlFor={name} className="toggle-label">
				{title}
			</label>
		</>
	);
};

export default ToggleButton;
