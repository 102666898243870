import { EXPERIMENT_TYPES_LOADED, EXPERIMENT_TYPES_ERROR, EXPERIMENT_TYPE_CREATED, EXPERIMENT_TYPE_UPDATED } from "./types";
import { getExperimentTypes, createExperimentType, updateExperimentType } from "../utils/apiCall";

export const loadExperimentTypes = () => async (dispatch) => {
	try {
		const experimentTypes = await getExperimentTypes();

		dispatch({
			type: EXPERIMENT_TYPES_LOADED,
			payload: experimentTypes,
		});
	} catch (err) {
		dispatch({
			type: EXPERIMENT_TYPES_ERROR,
		});
	}
};

export const createExperimentTypeAction = (typeName) => async (dispatch) => {
	try {
		const experimentType = await createExperimentType(typeName);

		dispatch({
			type: EXPERIMENT_TYPE_CREATED,
			payload: experimentType,
		});

		dispatch(loadExperimentTypes());
	} catch (err) {
		dispatch({
			type: EXPERIMENT_TYPES_ERROR,
		});
	}
};

export const updateExperimentTypeAction = (id, name) => async (dispatch) => {
	try {
		const experimentTypes = await updateExperimentType(id, name);

		dispatch({
			type: EXPERIMENT_TYPE_UPDATED,
			payload: experimentTypes,
		});

		dispatch(loadExperimentTypes());
	} catch (err) {
		dispatch({
			type: EXPERIMENT_TYPES_ERROR,
		});
	}
};
