import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import timezone from "./timezone";
import config from "./config";
import projectType from "./projectType";
import experimentType from "./experimentType";
import location from "./location";

//import appLinks from "./appLinks";

export default combineReducers({
  alert,
  auth,
  timezone,
  config,
  projectType,
  location,
  experimentType
});
