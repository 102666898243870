import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { loadUser } from "../../actions/auth";

const LimsLogin = ({ useOpenSocial, loadUser, isAuthenticated }) => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const url = params.get("url");

	useEffect(() => {
		loginRedirect();
	}, []);

	const loginRedirect = async () => {
		const token = params.get("token");

		if (useOpenSocial === "true") {
			window.location = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}/api/opensocial/login?RelayState=${url}`;
		} else {
			localStorage.setItem("token", token);
			loadUser();
		}
	};

	if (isAuthenticated) {
		return <Navigate to={url} />;
	}

	return <></>;
};

const mapStateToProps = (state) => ({
	useOpenSocial: state.config.useOpenSocial,
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { loadUser })(LimsLogin);
