import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DocEditor from "./DocEditor";
import SideBar from "../layout/SideBar";
import { getTemplate, getTemplateKey, getProjects } from "../../utils/apiCall";

const TemplateBuilder = ({ user }) => {
	const [template, setTemplate] = useState({});
	const [templateKey, setTemplateKey] = useState("");
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);

	const { templateId } = useParams();

	const url = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}`;

	useEffect(() => {
		const fetchData = async () => {
			const template = await getTemplate(templateId);
			let projects = await getProjects();

			setTemplate(template);
			setTemplateKey(await getTemplateKey(template.id));
			setProjects(projects);
			setLoading(false);
		};

		fetchData();
	}, [templateId, url, user]);

	const spinner = (
		<div className="d-flex justify-content-center">
			<div className="spinner-border m-5" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);

	return (
		<Fragment>
			{loading && spinner}
			{!loading && (
				<div className="container-fluid white-background">
					<div className="row">
						<div className="col-lg-2 hidden-md">
							<div className="side-bar">
								<SideBar projectList={[].concat.apply([], projects)} />
							</div>
						</div>
						<div className="col-12 col-lg-10 workspace">
							<div className="container-fluid">
								<nav className="navbar navbar-expand-xl navbar-light">
									<h3>{template.title}</h3>
									<button
										className="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation">
										<span className="navbar-toggler-icon"></span>
									</button>
									<div className="collapse navbar-collapse" id="navbarSupportedContent">
										<ul className="navbar-nav ml-auto">
											<li className="nav-item mr-3">
												<Link to="/projects" className="link">
													<i className="fas fa-project-diagram" />
													<span> Projects</span>
												</Link>
											</li>
											<li className="nav-item mr-3">
												<Link to="/search" className="link">
													<i className="fas fa-search" />
													<span> Search</span>
												</Link>
											</li>
											<li className="nav-item">
												<Link to="/logs" className="link">
													<i className="fas fa-list" />
													<span> Activity Log</span>
												</Link>
											</li>
										</ul>
									</div>
								</nav>
								<div className="row">
									<div className="col-12 p-0">
										<DocEditor
											config={{
												document: {
													fileType: template.documentType,
													title: template.title,
													key: templateKey,
													url: url + "/files/templates/" + template.id + "." + template.documentType,
												},
												documentType: template.documentType === "docx" ? "word" : "cell",
												editorConfig: {
													callbackUrl:
														url +
														"/api/onlyoffice/track?fileName=" +
														template.id +
														"." +
														template.documentType +
														"&description=" +
														template.description +
														"&title=" +
														template.title +
														"&urlPath=" +
														window.location.href +
														"&userName=" +
														user.FirstName +
														" " +
														user.LastName +
														"&token=" +
														localStorage.token +
														"&flag=" +
														1,
													anonymous: {
														request: false,
													},
													user: {
														name: `${user.FirstName} ${user.LastName}`,
														id: user.UserID.toString(),
													},
												},
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

TemplateBuilder.propTypes = {
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(TemplateBuilder);
