import { LOGIN_SUCCESS, LOGIN_FAIL, USER_LOADED, AUTH_ERROR, LOGOUT } from "../actions/types";

const initialState = {
	token: localStorage.getItem("token"),
	isAuthenticated: false,
	loading: false,
	user: {
		UserID: null,
		LoginID: null,
		FirstName: null,
		LastName: null,
		externalUserId: null,
		roles: null,
	},
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case USER_LOADED:
			delete payload.Password;
			return { ...state, isAuthenticated: true, loading: false, user: payload };
		case LOGIN_SUCCESS:
			localStorage.setItem("token", payload.token);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
			};
		case LOGIN_FAIL:
		case AUTH_ERROR:
		case LOGOUT:
			localStorage.clear();
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: {
					UserID: null,
					LoginID: null,
					FirstName: null,
					LastName: null,
					externalUserId: null,
					nameIDFormat: null,
					nameID: null,
				},
			};
		default:
			return state;
	}
}
