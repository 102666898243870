import axios from "../constants/axiosRequest";
import store from "../store";
import { setAlert } from "../actions/alert";
import logger from "./logger";

const hostName = `${window._env_.REACT_APP_NODE_SERVER_HOSTNAME}:${window._env_.REACT_APP_NODE_SERVER_PORT}`;

export const forceSaveDocument = async (key, userId) => {
	try {
		const payload = {
			c: "forcesave",
			key: key,
			userdata: userId,
		};

		const res = await axios.post(hostName + "/api/onlyoffice/commandService", payload);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getExperimentKey = async (experimentId) => {
	try {
		const res = await axios.get(hostName + "/api/onlyoffice/experiments/" + experimentId + "/key");
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const updateExperimentKey = async (experimentId) => {
	try {
		const res = await axios.put(hostName + "/api/onlyoffice/experiments/" + experimentId + "/key");
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getTemplateKey = async (templateId) => {
	try {
		const res = await axios.get(hostName + "/api/onlyoffice/templates/" + templateId + "/key");
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getUser = async () => {
	try {
		const res = await axios.get(hostName + "/api/users/id");
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getAllUsers = async () => {
	try {
		const res = await axios.get(hostName + "/api/users");
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const usersExist = async () => {
	try {
		const res = await axios.get(hostName + "/api/users/exists");
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createFirstUser = async (data) => {
	try {
		const res = await axios.post(hostName + "/api/users/admin", data);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const passwordResetEmail = async (email) => {
	try {
		const res = await axios.post(hostName + "/api/users/passwordResetEmail", { email });
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const resetPassword = async (password, confirmPassword, token) => {
	try {
		const config = {
			headers: {
				"x-auth-token": token,
			},
		};
		const res = await axios.post(
			hostName + "/api/users/resetPassword",
			{
				password,
				confirmPassword,
			},
			config
		);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createUser = async (data) => {
	try {
		const res = await axios.post(hostName + "/api/users", data);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const updateUser = async (data) => {
	try {
		const res = await axios.put(hostName + "/api/users", data);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const deleteUser = async (id) => {
	try {
		const res = await axios.delete(hostName + "/api/users/" + id);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const setUserRoles = async (data) => {
	try {
		const res = await axios.put(hostName + "/api/users/roles", data);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const exportDocument = async (projectId, experimentId) => {
	try {
		const res = await axios.post(hostName + `/api/projects/${projectId}/experiments/${experimentId}/exportDocument`);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const exportToLims = async (projectId, experimentId, documentTypeId, locationId) => {
	try {
		const res = await axios.post(hostName + `/api/projects/${projectId}/experiments/${experimentId}/limsExport`, { documentTypeId, locationId });
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getLimsDocumentTypes = async () => {
	try {
		const res = await axios.get(hostName + "/api/projects/experiments/documentTypes");
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getLimsLocations = async () => {
	try {
		const res = await axios.get(hostName + `/api/projects/experiments/locations`);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getProject = async (projectId) => {
	try {
		const res = await axios.get(hostName + "/api/projects/" + projectId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getProjects = async () => {
	try {
		const res = await axios.get(hostName + "/api/projects");
		const data = res.data;
		const dateNow = new Date().getTime() / 1000 / 60 / 60 / 24;

		for (let i = 0; i < data.length; i++) {
			const dateUpdated = new Date(data[i].updatedAt).getTime() / 1000 / 60 / 60 / 24;

			if (dateNow - dateUpdated > 30) {
				const project = {
					active: !data[i].active,
					userName: "System Administrator",
				};
				const updatedProject = await editProject(project, data[i].id);
				data[i] = updatedProject.data;
			}
		}

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createProject = async (title, type, typeId, userName, locationId) => {
	try {
		const res = await axios.post(hostName + "/api/projects", {
			title,
			projectTypeId: type,
			externalPK: typeId,
			userName,
			locationId,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const editProject = async (project, projectId) => {
	try {
		const res = await axios.put(hostName + "/api/projects/" + projectId, project);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getProjectTypes = async () => {
	try {
		const res = await axios.get(hostName + "/api/projectTypes");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createProjectType = async (typeName, shortName, active) => {
	try {
		const res = await axios.post(hostName + "/api/projectTypes", {
			typeName,
			active,
			shortName,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const updateProjectType = async (id, name, shortName, active) => {
	try {
		const res = await axios.put(hostName + "/api/projectTypes", { id, name, shortName, active });

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getExperiments = async (projectId) => {
	try {
		const res = await axios.get(hostName + "/api/projects/" + projectId + "/experiments");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createExperiment = async (title, description, documentType, projectId, instrumentId, testId, userName, visibility, experimentTypeId) => {
	try {
		const res = await axios.post(hostName + "/api/projects/" + projectId + "/experiments", {
			title,
			description,
			documentType,
			instrumentId,
			testId,
			userName,
			visibility,
			experimentTypeId,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const editExperiment = async (title, description, projectId, experimentId, instrumentId, testId, userName, experimentTypeId) => {
	try {
		const res = await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
			title,
			description,
			instrumentId,
			testId,
			userName,
			experimentTypeId,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getProjectExperiments = async () => {
	try {
		const res = await axios.get(`${hostName}/api/search/experiments`);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getUserInstruments = async (externalUserId) => {
	try {
		const res = await axios.get(`${hostName}/api/instruments/` + externalUserId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getUserTests = async (externalUserId) => {
	try {
		const res = await axios.get(`${hostName}/api/tests/` + externalUserId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getNotebook = async (projectId, experimentId) => {
	try {
		const res = await axios.get(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const editNotebook = async (title, description, projectId, experimentId, userName) => {
	try {
		const res = await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
			title,
			description,
			userName,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const publishNotebook = async (isPublished, publisher, publishedAt, projectId, experimentId, userName) => {
	try {
		return await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
			isPublished,
			publisher,
			publishedAt,
			userName,
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const reviewNotebook = async (isReviewed, reviewer, reviewedAt, projectId, experimentId, userName) => {
	try {
		return await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
			isReviewed,
			reviewer,
			reviewedAt,
			userName,
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const getAllDataConnections = async () => {
	try {
		const res = await axios.get(hostName + "/api/dataConnections/");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getDataConnection = async (dataConnectionId) => {
	try {
		const res = await axios.get(hostName + "/api/dataConnections/" + dataConnectionId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createDataConnection = async (name, baseUrl, username, password, token, header, authTypeId) => {
	try {
		const res = await axios.post(hostName + "/api/dataConnections/", {
			name,
			baseUrl,
			username,
			password,
			token,
			header,
			authTypeId,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const updateDataConnection = async (dataConnectionId, name, baseUrl, username, password, token, header, authTypeId) => {
	try {
		const res = await axios.put(hostName + "/api/dataConnections/" + dataConnectionId, {
			name,
			baseUrl,
			username,
			password,
			token,
			header,
			authTypeId,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const deleteDataConnection = async (dataConnectionId) => {
	try {
		const res = await axios.delete(hostName + "/api/dataConnections/" + dataConnectionId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const verifyBaseURl = async (dataConnectionId) => {
	try {
		const res = await axios.get(hostName + "/api/dataConnections/" + dataConnectionId + "/verifyUrl");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getAllAuthTypes = async () => {
	try {
		const res = await axios.get(hostName + "/api/dataConnections/authTypes");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getAllEventTypes = async () => {
	try {
		const res = await axios.get(hostName + "/api/dataConnections/:dataConnectionId/events/eventTypes");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getAllEvents = async (dataConnectionId) => {
	try {
		const res = await axios.get(hostName + "/api/dataConnections/" + dataConnectionId + "/events");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getEvent = async (dataConnectionId, eventId) => {
	try {
		const res = await axios.get(hostName + "/api/dataConnections/" + dataConnectionId + "/events/" + eventId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createEvent = async (dataConnectionId, name, endpoint) => {
	try {
		const res = await axios.post(hostName + "/api/dataConnections/" + dataConnectionId + "/events", {
			name,
			endpoint,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const updateEvent = async (dataConnectionId, eventId, endpoint) => {
	try {
		const res = await axios.put(hostName + "/api/dataConnections/" + dataConnectionId + "/events/" + eventId, {
			endpoint,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const deleteEvent = async (dataConnectionId, eventId) => {
	try {
		const res = await axios.delete(hostName + "/api/dataConnections/" + dataConnectionId + "/events/" + eventId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const templateAutofill = async (projectId, experimentId, templateId) => {
	try {
		const res = await axios.post(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId + "/templateAutofill", { templateId });

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const importLocations = async (dataConnectionId) => {
	try {
		const res = await axios.post(hostName + "/api/locations/importLocations", { dataConnectionId });

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const importUsers = async (dataConnectionId) => {
	try {
		const res = await axios.post(hostName + "/api/users/importUsers", { dataConnectionId });
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getLogs = async () => {
	try {
		const res = await axios.get(hostName + "/api/logs");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createLog = async (event, message) => {
	try {
		let urlPath = window.location.href;

		return await axios.post(hostName + "/api/logs", {
			event,
			urlPath,
			message,
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const getTemplates = async () => {
	try {
		const res = await axios.get(hostName + "/api/templates");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getTemplate = async (templateId) => {
	try {
		const res = await axios.get(hostName + "/api/templates/" + templateId);

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createTemplate = async (title, description, autofill, documentType, userName, dataObject, experimentTypeId) => {
	try {
		return await axios.post(hostName + "/api/templates", {
			title,
			description,
			autofill,
			documentType,
			userName,
			dataObject,
			experimentTypeId,
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const editTemplate = async (title, description, autofill, templateId, userName, dataObject, experimentTypeId) => {
	try {
		return await axios.put(hostName + "/api/templates/" + templateId, {
			title,
			description,
			autofill,
			userName,
			dataObject,
			experimentTypeId,
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const loadTemplate = async (templateId, documentType, projectId, experimentId, userName) => {
	try {
		return await axios.put(hostName + "/api/projects/" + projectId + "/experiments/" + experimentId, {
			templateId,
			documentType,
			userName,
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const uploadFiles = async (url, data) => {
	try {
		return await axios.post(url, data);
	} catch (err) {
		handleErrors(err);
	}
};

export const uploadImage = async (url, data) => {
	try {
		return await axios.post(url, data);
	} catch (err) {
		handleErrors(err);
	}
};

export const setOpensocialConfig = async (config) => {
	try {
		return await axios.post(`${hostName}/api/opensocial/config`, config);
	} catch (err) {
		handleErrors(err);
	}
};

export const getOpensocialConfig = async () => {
	try {
		return await axios.get(`${hostName}/api/opensocial/config`);
	} catch (err) {
		handleErrors(err);
	}
};

export const getConfig = async (configName) => {
	try {
		return await axios.get(`${hostName}/api/config/${configName}`);
	} catch (err) {
		handleErrors(err);
	}
};

export const setConfig = async (configName, configValue) => {
	try {
		await axios.post(`${hostName}/api/config/${configName}`, {
			configValue: configValue.toString(),
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const getFileConfig = async (configName) => {
	try {
		return await axios.get(`${hostName}/api/config/file/${configName}`);
	} catch (err) {
		handleErrors(err);
	}
};

export const setFileConfig = async (configName, data) => {
	try {
		await axios.post(`${hostName}/api/config/file/${configName}`, data);
	} catch (err) {
		handleErrors(err);
	}
};

export const getRoles = async () => {
	try {
		const res = await axios.get(`${hostName}/api/roles/`);
		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createRole = async (data) => {
	try {
		return await axios.post(`${hostName}/api/roles/`, data);
	} catch (err) {
		handleErrors(err);
	}
};

export const updateRole = async (id, name, description, active) => {
	try {
		return await axios.put(`${hostName}/api/roles/`, {
			id,
			name,
			description,
			active,
		});
	} catch (err) {
		handleErrors(err);
	}
};

export const setRoleProjectTypes = async (data) => {
	try {
		return await axios.put(`${hostName}/api/roles/projectTypes`, data);
	} catch (err) {
		handleErrors(err);
	}
};

export const getAllLocations = async () => {
	try {
		const locations = await axios.get(`${hostName}/api/locations`);
		return locations.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getAllUserLocations = async () => {
	try {
		const locations = await axios.get(`${hostName}/api/locations/user`);
		return locations.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getCurrentUserLocation = async () => {
	try {
		const location = await axios.get(`${hostName}/api/locations/user/current`);
		return location.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const setCurrentUserLocation = async (locationId) => {
	try {
		const location = await axios.post(`${hostName}/api/locations/user/current`, {
			locationId,
		});
		return location.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getLocationById = async (locationId) => {
	try {
		const location = await axios.get(`${hostName}/api/locations/${locationId}`);
		return location.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createLocation = async (name) => {
	try {
		const location = await axios.post(`${hostName}/api/locations`, { name });
		return location.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const updateLocationById = async (locationId, name) => {
	try {
		const location = await axios.put(`${hostName}/api/locations/${locationId}`, { name });
		return location.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const deleteLocationById = async (locationId) => {
	try {
		return await axios.delete(`${hostName}/api/locations/${locationId}`);
	} catch (err) {
		handleErrors(err);
	}
};

export const setUserLocations = async (userId, locationIdArr) => {
	try {
		const locations = await axios.put(`${hostName}/api/users/locations/`, {
			userId,
			locationIdArr,
		});

		return locations.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const getExperimentTypes = async () => {
	try {
		const res = await axios.get(hostName + "/api/experimentTypes");

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const createExperimentType = async (typeName, active) => {
	try {
		const res = await axios.post(hostName + "/api/experimentTypes", {
			typeName,
			active,
		});

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const updateExperimentType = async (id, typeName, useACL, roles, active) => {
	try {
		const res = await axios.put(hostName + "/api/experimentTypes", { id, typeName, useACL, roles, active });

		return res.data;
	} catch (err) {
		handleErrors(err);
	}
};

export const setRoleExperimentTypes = async (data) => {
	try {
		return await axios.put(`${hostName}/api/roles/experimentTypes`, data);
	} catch (err) {
		handleErrors(err);
	}
};

export const getExperimentsPermissions = async (projectId) => {
	try {
		const permissions = await axios.get(`${hostName}/api/projects/${projectId}/experiments/permissions`);
		return permissions.data;
	} catch (err) {
		handleErrors(err);
	}
};

const handleErrors = (err) => {
	if (err && err.response && err.response.data && err.response.data.errors) {
		const errors = err.response.data.errors;

		if (errors) {
			for (let i = 0; i < errors.length; i++) {
				logger("error", errors[i].msg);
				store.dispatch(setAlert(errors[i].msg, "danger"));
			}
		}
	} else {
		store.dispatch(setAlert("Server Error", "danger"));
	}
};
