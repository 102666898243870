import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

const ModalLink = ({ link, title, body, modalShow, setModalShow }) => {
	return (
		<>
			<button className="anchor-link" onClick={() => setModalShow(true)}>
				{link}
			</button>
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="md"
				aria-labelledby="form-modal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="form-modal">{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{body}</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(ModalLink);
